const videos = {
    "1445": {
        "id": "1445",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Abi",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/0bL6iddu9AI",
        "filename": "ABI_Abi_Nigeria_PHLJerrycan_FTF_Final",
        "files": [
            "ABI_Abi_Nigeria_PHLJerrycan_FTF_Final.3gp",
            "ABI_Abi_Nigeria_PHLJerrycan_FTF_Final.mov",
            "ABI_Abi_Nigeria_PHLJerrycan_FTF_Final.mp4",
            "ABI_Abi_Nigeria_PHLJerrycan_FTF_Final_Light.3gp",
            "ABI_Abi_Nigeria_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-11 10:02:56"
    },
    "1164": {
        "id": "1164",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Akuapem",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/one5R7JI-7Y",
        "filename": "AKUA_Akuapem_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "AKUA_Akuapem_Ghana_CoronavirusMarketplace_Final.3gp",
            "AKUA_Akuapem_Ghana_CoronavirusMarketplace_Final.mov",
            "AKUA_Akuapem_Ghana_CoronavirusMarketplace_Final.mp4",
            "AKUA_Akuapem_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "AKUA_Akuapem_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-26 11:31:07"
    },
    "1218": {
        "id": "1218",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Akuapem",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/eNjT03K-2e4",
        "filename": "AKUA_Akuapem Twi_Ghana_CoronavirusMarketShopper_Final",
        "files": [
            "AKUA_Akuapem Twi_Ghana_CoronavirusMarketShopper_Final.3gp",
            "AKUA_Akuapem Twi_Ghana_CoronavirusMarketShopper_Final.mov",
            "AKUA_Akuapem Twi_Ghana_CoronavirusMarketShopper_Final.mp4",
            "AKUA_Akuapem Twi_Ghana_CoronavirusMarketShopper_Final_Light.3gp",
            "AKUA_Akuapem Twi_Ghana_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-10 12:14:12"
    },
    "1224": {
        "id": "1224",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Akuapem",
        "country": "Ghana",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/e0bLvLaayig",
        "filename": "AKUA_Akuapem_Ghana_CoronavirusMarketManager_Final",
        "files": [
            "AKUA_Akuapem_Ghana_CoronavirusMarketManager_Final.3gp",
            "AKUA_Akuapem_Ghana_CoronavirusMarketManager_Final.mov",
            "AKUA_Akuapem_Ghana_CoronavirusMarketManager_Final.mp4",
            "AKUA_Akuapem_Ghana_CoronavirusMarketManager_Final_Light.3gp",
            "AKUA_Akuapem_Ghana_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-13 09:43:09"
    },
    "961": {
        "id": "961",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Akuapem",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/ge7ViZGQk24",
        "filename": "AKUA_Akuapem_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "AKUA_Akuapem_Ghana_PHLJerryCanFTF_Final.3gp",
            "AKUA_Akuapem_Ghana_PHLJerryCanFTF_Final.mov",
            "AKUA_Akuapem_Ghana_PHLJerryCanFTF_Final.mp4",
            "AKUA_Akuapem_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "AKUA_Akuapem_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-27 13:23:03"
    },
    "1295": {
        "id": "1295",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "Akuapem Twi",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/v3TC5tQSHKw",
        "filename": "AKUA_Akuapem_Twi_Ghana_CoronavirusMaskUsageVar2_Final",
        "files": [
            "AKUA_Akuapem_Twi_Ghana_CoronavirusMaskUsageVar2_Final.3gp",
            "AKUA_Akuapem_Twi_Ghana_CoronavirusMaskUsageVar2_Final.mov",
            "AKUA_Akuapem_Twi_Ghana_CoronavirusMaskUsageVar2_Final.mp4",
            "AKUA_Akuapem_Twi_Ghana_CoronavirusMaskUsageVar2_Final_Light.3gp",
            "AKUA_Akuapem_Twi_Ghana_CoronavirusMaskUsageVar2_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-02 14:21:32"
    },
    "1389": {
        "id": "1389",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Akuapem Twi",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/11g-07jI33k",
        "filename": "AKUA_Akuapem Twi_Ghana_CoronavirusPublicTransportation_Final",
        "files": [
            "AKUA_Akuapem Twi_Ghana_CoronavirusPublicTransportation_Final.3gp",
            "AKUA_Akuapem Twi_Ghana_CoronavirusPublicTransportation_Final.mov",
            "AKUA_Akuapem Twi_Ghana_CoronavirusPublicTransportation_Final.mp4",
            "AKUA_Akuapem Twi_Ghana_CoronavirusPublicTransportation_Final_Light.3gp",
            "AKUA_Akuapem Twi_Ghana_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-14 14:26:49"
    },
    "1403": {
        "id": "1403",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Akuapem Twi",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/LgEyPG0msMM",
        "filename": "AKUA_Akuapem_Ghana_PICSVar1_Final",
        "files": [
            "AKUA_Akuapem_Ghana_PICSVar1_Final.3gp",
            "AKUA_Akuapem_Ghana_PICSVar1_Final.mov",
            "AKUA_Akuapem_Ghana_PICSVar1_Final.mp4",
            "AKUA_Akuapem_Ghana_PICSVar1_Final_Light.3gp",
            "AKUA_Akuapem_Ghana_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-19 11:06:21"
    },
    "1137": {
        "id": "1137",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Arabic",
        "country": "Egypt",
        "subtitles": null,
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/k0dF5KXuzU0",
        "filename": "ARA2_Arabic_Egypt_CoronavirusMarketplace_Final",
        "files": [
            "ARA2_Arabic_Egypt_CoronavirusMarketplace_Final.3gp",
            "ARA2_Arabic_Egypt_CoronavirusMarketplace_Final.mov",
            "ARA2_Arabic_Egypt_CoronavirusMarketplace_Final.mp4",
            "ARA2_Arabic_Egypt_CoronavirusMarketplace_Final_Light.3gp",
            "ARA2_Arabic_Egypt_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-02 09:53:28"
    },
    "1150": {
        "id": "1150",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Asante Twi",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/D4642piQgRw",
        "filename": "AKA_Asante Twi_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "AKA_Asante Twi_Ghana_CoronavirusMarketplace_Final.3gp",
            "AKA_Asante Twi_Ghana_CoronavirusMarketplace_Final.mov",
            "AKA_Asante Twi_Ghana_CoronavirusMarketplace_Final.mp4",
            "AKA_Asante Twi_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "AKA_Asante Twi_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-16 12:22:35"
    },
    "1476": {
        "id": "1476",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Asante Twi",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/r1IL47IMY0k",
        "filename": "AKA1_Asante Twi_Ghana_CoronavirusNewcastleDisease_Final",
        "files": [
            "AKA1_Asante Twi_Ghana_CoronavirusNewcastleDisease_Final.3gp",
            "AKA1_Asante Twi_Ghana_CoronavirusNewcastleDisease_Final.mov",
            "AKA1_Asante Twi_Ghana_CoronavirusNewcastleDisease_Final.mp4",
            "AKA1_Asante Twi_Ghana_CoronavirusNewcastleDisease_Final_Light.3gp",
            "AKA1_Asante Twi_Ghana_CoronavirusNewcastleDisease_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-01 09:49:55"
    },
    "1506": {
        "id": "1506",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "Asante Twi",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/XTI0PX5eDp0",
        "filename": " AKA_Asante Twi_Ghana_CoronavirusMaskUsageVar2_Final",
        "files": [],
        "publishDate": "2021-12-09 11:03:01"
    },
    "944": {
        "id": "944",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Asante Twi",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/zmbP6MuLQnk",
        "filename": "AKA_Asante Twi_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "AKA_Asante Twi_Ghana_PHLJerryCanFTF_Final.3gp",
            "AKA_Asante Twi_Ghana_PHLJerryCanFTF_Final.mov",
            "AKA_Asante Twi_Ghana_PHLJerryCanFTF_Final.mp4",
            "AKA_Asante Twi_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "AKA_Asante Twi_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-09 04:57:44"
    },
    "1160": {
        "id": "1160",
        "topic": "CoronavirusMarketShopperVar1",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace Variant for Bangladesh",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic. This is a variant animation for Bangladesh.",
        "custom": null,
        "url": "//www.youtube.com/embed/sQQL0iyBE8k",
        "filename": "BEN_Bangla_Bangladesh_CoronavirusMarketShopperVar1_Final",
        "files": [
            "BEN_Bangla_Bangladesh_CoronavirusMarketShopperVar1_Final.3gp",
            "BEN_Bangla_Bangladesh_CoronavirusMarketShopperVar1_Final.mov",
            "BEN_Bangla_Bangladesh_CoronavirusMarketShopperVar1_Final.mp4",
            "BEN_Bangla_Bangladesh_CoronavirusMarketShopperVar1_Final_Light.3gp",
            "BEN_Bangla_Bangladesh_CoronavirusMarketShopperVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-21 21:34:13"
    },
    "1302": {
        "id": "1302",
        "topic": "CoronavirusMarketplaceVar1",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "How to Sell Safely in the Marketplace during the Coronavirus Pandemic - Variant for Bangladesh",
        "description": "The arrival of the Coronavirus changed many parts of community life. Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic. This animation is a variant for Bangladesh.",
        "custom": null,
        "url": "//www.youtube.com/embed/fUM8_v4MdQA",
        "filename": "BEN_Bangla_Bangladesh_CoronavirusMarketplaceVar1_Final",
        "files": [
            "BEN_Bangla_Bangladesh_CoronavirusMarketplaceVar1_Final.3gp",
            "BEN_Bangla_Bangladesh_CoronavirusMarketplaceVar1_Final.mov",
            "BEN_Bangla_Bangladesh_CoronavirusMarketplaceVar1_Final.mp4",
            "BEN_Bangla_Bangladesh_CoronavirusMarketplaceVar1_Final_Light.3gp",
            "BEN_Bangla_Bangladesh_CoronavirusMarketplaceVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-07 22:40:17"
    },
    "1310": {
        "id": "1310",
        "topic": "CoronavirusMaskUsageVar3",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "Properly Using Facemasks - Bangladesh Version",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This animation is a variant for Bangladesh.",
        "custom": null,
        "url": "//www.youtube.com/embed/g6aIldzM80Q",
        "filename": "BEN_Bengali_Bangladesh_CoronavirusMaskUsageVar3_Final",
        "files": [
            "BEN_Bengali_Bangladesh_CoronavirusMaskUsageVar3_Final.3gp",
            "BEN_Bengali_Bangladesh_CoronavirusMaskUsageVar3_Final.mov",
            "BEN_Bengali_Bangladesh_CoronavirusMaskUsageVar3_Final.mp4",
            "BEN_Bengali_Bangladesh_CoronavirusMaskUsageVar3_Final_Light.3gp",
            "BEN_Bengali_Bangladesh_CoronavirusMaskUsageVar3_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-09 22:32:38"
    },
    "1326": {
        "id": "1326",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/qHbA1ZOcSWY",
        "filename": "BEN_Bangla_Bangladesh_CoronavirusPublicTransportation_Final",
        "files": [
            "BEN_Bangla_Bangladesh_CoronavirusPublicTransportation_Final.3gp",
            "BEN_Bangla_Bangladesh_CoronavirusPublicTransportation_Final.mov",
            "BEN_Bangla_Bangladesh_CoronavirusPublicTransportation_Final.mp4",
            "BEN_Bangla_Bangladesh_CoronavirusPublicTransportation_Final_Light.3gp",
            "BEN_Bangla_Bangladesh_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-19 19:35:48"
    },
    "1424": {
        "id": "1424",
        "topic": "PICSVar3",
        "category": "Agriculture",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": "English",
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes. This animation is a variant for Bangladesh.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/JQqVUmr1Exo",
        "filename": "Sequence 01_1.mp4",
        "files": [],
        "publishDate": "2021-11-04 06:24:23"
    },
    "1425": {
        "id": "1425",
        "topic": "CoronavirusMarketShopperVar1",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "CoVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic. This is a variant animation for Bangladesh.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/G79OQEJ47Xs",
        "filename": "Sequence 01_3.mp4",
        "files": [],
        "publishDate": "2021-11-04 06:36:26"
    },
    "1426": {
        "id": "1426",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/vtLUosjeDPg",
        "filename": "Sequence 01_2.mp4",
        "files": [],
        "publishDate": "2021-11-04 06:45:29"
    },
    "1427": {
        "id": "1427",
        "topic": "CoronavirusMarketplaceVar1",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/_yALXSo1v_o",
        "filename": "Sequence 01_4.mp4",
        "files": [],
        "publishDate": "2021-11-04 06:48:45"
    },
    "1428": {
        "id": "1428",
        "topic": "CoronavirusMaskUsageVar3",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This animation is a variant for Bangladesh.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/-sqTH_3OtHM",
        "filename": "Sequence 01.mp4",
        "files": [],
        "publishDate": "2021-11-04 06:58:20"
    },
    "1466": {
        "id": "1466",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Bangla",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/CjeBmDL0tCM",
        "filename": "BEN-Bangla_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final",
        "files": [
            "BEN-Bangla_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final.3gp",
            "BEN-Bangla_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final.mov",
            "BEN-Bangla_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final.mp4",
            "BEN-Bangla_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final_Light.3gp",
            "BEN-Bangla_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-30 09:03:08"
    },
    "1286": {
        "id": "1286",
        "topic": "PICSVar3",
        "category": "Agriculture",
        "language": "Bengali",
        "country": "Bangladesh",
        "subtitles": "English",
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes. This animation is a variant for Bangladesh.",
        "custom": null,
        "url": "//www.youtube.com/embed/Nf-hZPtQUc0",
        "filename": "BEN_Bengali_Bangladesh_PICSVar3_EN SUB_Final",
        "files": [
            "BEN_Bengali_Bangladesh_PICSVar3_EN SUB_Final.3gp",
            "BEN_Bengali_Bangladesh_PICSVar3_EN SUB_Final.mov",
            "BEN_Bengali_Bangladesh_PICSVar3_EN SUB_Final.mp4",
            "BEN_Bengali_Bangladesh_PICSVar3_EN SUB_Final_Light.3gp",
            "BEN_Bengali_Bangladesh_PICSVar3_EN SUB_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-25 22:29:36"
    },
    "955": {
        "id": "955",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Bengali",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/Y_Uk7ocqN9Q",
        "filename": "BEN_Bengali_Bangladesh_PHLJerryCanFTF_Final",
        "files": [
            "BEN_Bengali_Bangladesh_PHLJerryCanFTF_Final.3gp",
            "BEN_Bengali_Bangladesh_PHLJerryCanFTF_Final.mov",
            "BEN_Bengali_Bangladesh_PHLJerryCanFTF_Final.mp4",
            "BEN_Bengali_Bangladesh_PHLJerryCanFTF_Final_Light.3gp",
            "BEN_Bengali_Bangladesh_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-18 06:56:52"
    },
    "1430": {
        "id": "1430",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Bono",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/5hE24a0MXI0",
        "filename": "BON_Bono_Ghana_PHLJerrycan_FTF_Final",
        "files": [
            "BON_Bono_Ghana_PHLJerrycan_FTF_Final.3gp",
            "BON_Bono_Ghana_PHLJerrycan_FTF_Final.mov",
            "BON_Bono_Ghana_PHLJerrycan_FTF_Final.mp4",
            "BON_Bono_Ghana_PHLJerrycan_FTF_Final_Light.3gp",
            "BON_Bono_Ghana_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-04 13:19:56"
    },
    "1463": {
        "id": "1463",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Bono",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/KJti_fNdKns",
        "filename": "BON_Bono_Ghana_CoronavirusNewcastleDisease_Final",
        "files": [
            "BON_Bono_Ghana_CoronavirusNewcastleDisease_Final.3gp",
            "BON_Bono_Ghana_CoronavirusNewcastleDisease_Final.mov",
            "BON_Bono_Ghana_CoronavirusNewcastleDisease_Final.mp4",
            "BON_Bono_Ghana_CoronavirusNewcastleDisease_Final_Light.3gp",
            "BON_Bono_Ghana_CoronavirusNewcastleDisease_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-23 12:33:55"
    },
    "1075": {
        "id": "1075",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Bukusu",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/6qdofMf9mM0",
        "filename": "BXK_Bukusu_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "BXK_Bukusu_Kenya_PHLJerryCanFTF_Final.3gp",
            "BXK_Bukusu_Kenya_PHLJerryCanFTF_Final.mov",
            "BXK_Bukusu_Kenya_PHLJerryCanFTF_Final.mp4",
            "BXK_Bukusu_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "BXK_Bukusu_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-12 12:57:38"
    },
    "1449": {
        "id": "1449",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Buli",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/m-LJmWwv5jM",
        "filename": "BUL-Buli_Ghana_PHLJerrycan_FTF_Final",
        "files": [
            "BUL-Buli_Ghana_PHLJerrycan_FTF_Final.3gp",
            "BUL-Buli_Ghana_PHLJerrycan_FTF_Final.mov",
            "BUL-Buli_Ghana_PHLJerrycan_FTF_Final.mp4",
            "BUL-Buli_Ghana_PHLJerrycan_FTF_Final_Light.3gp",
            "BUL-Buli_Ghana_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-17 10:20:50"
    },
    "1099": {
        "id": "1099",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Bura-Pabir",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/cBhdtyftPbk",
        "filename": "BWR_Bura-Pabir_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "BWR_Bura-Pabir_Nigeria_PHLJerryCanFTF_Final.3gp",
            "BWR_Bura-Pabir_Nigeria_PHLJerryCanFTF_Final.mov",
            "BWR_Bura-Pabir_Nigeria_PHLJerryCanFTF_Final.mp4",
            "BWR_Bura-Pabir_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "BWR_Bura-Pabir_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-21 09:07:23"
    },
    "1371": {
        "id": "1371",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Bura-Pabir",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/yB3ZGhUJE9o",
        "filename": "BWR_Bura-Pabir_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "BWR_Bura-Pabir_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "BWR_Bura-Pabir_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "BWR_Bura-Pabir_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "BWR_Bura-Pabir_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "BWR_Bura-Pabir_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-10 18:13:51"
    },
    "1385": {
        "id": "1385",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Bura-Pabir",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks ",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/Zi6iKOkLNS4",
        "filename": "BWR_Bura-Pabir_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "BWR_Bura-Pabir_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "BWR_Bura-Pabir_Nigeria_CoronavirusMaskUsage_Final.mov",
            "BWR_Bura-Pabir_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "BWR_Bura-Pabir_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "BWR_Bura-Pabir_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-13 13:28:21"
    },
    "1173": {
        "id": "1173",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Chichewa-Sign Language",
        "country": "Malawi",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/mLygl6A_lLk",
        "filename": "NY_Chichewa_Malawi_SignLanguage_CoronavirusMarketplace_Final",
        "files": [
            "NY_Chichewa_Malawi_SignLanguage_CoronavirusMarketplace_Final.3gp",
            "NY_Chichewa_Malawi_SignLanguage_CoronavirusMarketplace_Final.mov",
            "NY_Chichewa_Malawi_SignLanguage_CoronavirusMarketplace_Final.mp4",
            "NY_Chichewa_Malawi_SignLanguage_CoronavirusMarketplace_Final_Light.3gp",
            "NY_Chichewa_Malawi_SignLanguage_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-01 07:43:39"
    },
    "1450": {
        "id": "1450",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Chidigo",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/v9B4HD3dAMQ",
        "filename": "DIG-Digo_Kenya_PHLJerrycan_FTF_Final",
        "files": [
            "DIG-Digo_Kenya_PHLJerrycan_FTF_Final.3gp",
            "DIG-Digo_Kenya_PHLJerrycan_FTF_Final.mov",
            "DIG-Digo_Kenya_PHLJerrycan_FTF_Final.mp4",
            "DIG-Digo_Kenya_PHLJerrycan_FTF_Final_Light.3gp",
            "DIG-Digo_Kenya_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-17 12:13:17"
    },
    "1248": {
        "id": "1248",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Chonyi",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace ",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/7u7JTobVO8A",
        "filename": "CHO_Chonyi_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "CHO_Chonyi_Kenya_CoronavirusMarketShopper_Final.3gp",
            "CHO_Chonyi_Kenya_CoronavirusMarketShopper_Final.mov",
            "CHO_Chonyi_Kenya_CoronavirusMarketShopper_Final.mp4",
            "CHO_Chonyi_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "CHO_Chonyi_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-07-23 08:16:45"
    },
    "1317": {
        "id": "1317",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Chonyi",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/B-i_MEovfLw",
        "filename": "COH_Chonyi_Kenya_PICSVar1_Final",
        "files": [
            "COH_Chonyi_Kenya_PICSVar1_Final.3gp",
            "COH_Chonyi_Kenya_PICSVar1_Final.mov",
            "COH_Chonyi_Kenya_PICSVar1_Final.mp4",
            "COH_Chonyi_Kenya_PICSVar1_Final_Light.3gp",
            "COH_Chonyi_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-16 11:46:33"
    },
    "1391": {
        "id": "1391",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Chonyi",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/9Gv_WLd1HFo",
        "filename": "COH_Chonyi_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "COH_Chonyi_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "COH_Chonyi_Kenya_CoronavirusPublicTransportation_Final.mov",
            "COH_Chonyi_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "COH_Chonyi_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "COH_Chonyi_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-15 12:03:30"
    },
    "1413": {
        "id": "1413",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Chonyi",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/gYYXuxwbi1w",
        "filename": "CHO_Chonyi_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "CHO_Chonyi_Kenya_CoronavirusMaskUsage_Final.3gp",
            "CHO_Chonyi_Kenya_CoronavirusMaskUsage_Final.mp4",
            "CHO_Chonyi_Kenya_CoronavirusMaskUsage_Final_1.mov",
            "CHO_Chonyi_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "CHO_Chonyi_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-28 15:18:24"
    },
    "1170": {
        "id": "1170",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Dagaare",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/_FU0dX3sX-E",
        "filename": "DGA_Dagaare_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "DGA_Dagaare_Ghana_CoronavirusMarketplace_Final.3gp",
            "DGA_Dagaare_Ghana_CoronavirusMarketplace_Final.mov",
            "DGA_Dagaare_Ghana_CoronavirusMarketplace_Final.mp4",
            "DGA_Dagaare_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "DGA_Dagaare_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-31 10:12:26"
    },
    "962": {
        "id": "962",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Dagaare",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/J7VOuf7B05E",
        "filename": "DGA_Dagaare_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "DGA_Dagaare_Ghana_PHLJerryCanFTF_Final.3gp",
            "DGA_Dagaare_Ghana_PHLJerryCanFTF_Final.mov",
            "DGA_Dagaare_Ghana_PHLJerryCanFTF_Final.mp4",
            "DGA_Dagaare_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "DGA_Dagaare_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-27 13:31:13"
    },
    "1169": {
        "id": "1169",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Dagbani",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/9kPExL62bUc",
        "filename": "DAG_Dagbani_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "DAG_Dagbani_Ghana_CoronavirusMarketplace_Final.3gp",
            "DAG_Dagbani_Ghana_CoronavirusMarketplace_Final.mov",
            "DAG_Dagbani_Ghana_CoronavirusMarketplace_Final.mp4",
            "DAG_Dagbani_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "DAG_Dagbani_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-31 09:59:12"
    },
    "1469": {
        "id": "1469",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "Dagbani",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/Ti7uS_q6JvM",
        "filename": "DAG_Dagbani_Ghana_CoronavirusMaskUsageVar2_Final",
        "files": [
            "DAG_Dagbani_Ghana_CoronavirusMaskUsageVar2_Final.3gp",
            "DAG_Dagbani_Ghana_CoronavirusMaskUsageVar2_Final.mov",
            "DAG_Dagbani_Ghana_CoronavirusMaskUsageVar2_Final.mp4",
            "DAG_Dagbani_Ghana_CoronavirusMaskUsageVar2_Final_Light.3gp",
            "DAG_Dagbani_Ghana_CoronavirusMaskUsageVar2_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-01 07:53:57"
    },
    "941": {
        "id": "941",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Dagbani",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/uc0oYL04KWk",
        "filename": "DAG_Dagbani_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "DAG_Dagbani_Ghana_PHLJerryCanFTF_Final.3gp",
            "DAG_Dagbani_Ghana_PHLJerryCanFTF_Final.mov",
            "DAG_Dagbani_Ghana_PHLJerryCanFTF_Final.mp4",
            "DAG_Dagbani_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "DAG_Dagbani_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-06 10:02:32"
    },
    "1168": {
        "id": "1168",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Dangme",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/Mk1uay5HhWw",
        "filename": "ADA_Dangme_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "ADA_Dangme_Ghana_CoronavirusMarketplace_Final.3gp",
            "ADA_Dangme_Ghana_CoronavirusMarketplace_Final.mov",
            "ADA_Dangme_Ghana_CoronavirusMarketplace_Final.mp4",
            "ADA_Dangme_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "ADA_Dangme_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-31 09:42:36"
    },
    "945": {
        "id": "945",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Dangme",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/0EmqNfE_zdg",
        "filename": "ADA_Dangme_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "ADA_Dangme_Ghana_PHLJerryCanFTF_Final.3gp",
            "ADA_Dangme_Ghana_PHLJerryCanFTF_Final.mov",
            "ADA_Dangme_Ghana_PHLJerryCanFTF_Final.mp4",
            "ADA_Dangme_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "ADA_Dangme_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-09 05:09:32"
    },
    "1165": {
        "id": "1165",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Duruma",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/gtDAqirICwM",
        "filename": "DUR_Duruma_Kenya_PHLJerrycanFTF_Final",
        "files": [
            "DUR_Duruma_Kenya_PHLJerrycanFTF_Final.3gp",
            "DUR_Duruma_Kenya_PHLJerrycanFTF_Final.mov",
            "DUR_Duruma_Kenya_PHLJerrycanFTF_Final.mp4",
            "DUR_Duruma_Kenya_PHLJerrycanFTF_Final_Light.3gp",
            "DUR_Duruma_Kenya_PHLJerrycanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-26 11:42:40"
    },
    "1043": {
        "id": "1043",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Edo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/lf3wUtCmzXI",
        "filename": "BIN_Edo_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "BIN_Edo_Nigeria_PHLJerryCanFTF_Final.3gp",
            "BIN_Edo_Nigeria_PHLJerryCanFTF_Final.mov",
            "BIN_Edo_Nigeria_PHLJerryCanFTF_Final.mp4",
            "BIN_Edo_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "BIN_Edo_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-03 12:24:03"
    },
    "1197": {
        "id": "1197",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Edo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/4J-8RDwkHU4",
        "filename": "BIN_Edo_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "BIN_Edo_Nigeria_CoronavirusMarketplace_Final.3gp",
            "BIN_Edo_Nigeria_CoronavirusMarketplace_Final.mov",
            "BIN_Edo_Nigeria_CoronavirusMarketplace_Final.mp4",
            "BIN_Edo_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "BIN_Edo_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-27 13:20:49"
    },
    "1290": {
        "id": "1290",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Edo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/XDuc0ghCHPw",
        "filename": "EDO_Edo_Nigeria_CoronavirusMarketShopper_Final",
        "files": [
            "EDO_Edo_Nigeria_CoronavirusMarketShopper_Final.3gp",
            "EDO_Edo_Nigeria_CoronavirusMarketShopper_Final.mov",
            "EDO_Edo_Nigeria_CoronavirusMarketShopper_Final.mp4",
            "EDO_Edo_Nigeria_CoronavirusMarketShopper_Final_Light.3gp",
            "EDO_Edo_Nigeria_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-31 12:43:07"
    },
    "1311": {
        "id": "1311",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Edo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/hWZcvxILEK4",
        "filename": "BIN_Edo_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "BIN_Edo_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "BIN_Edo_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "BIN_Edo_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "BIN_Edo_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "BIN_Edo_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-14 14:15:23"
    },
    "1323": {
        "id": "1323",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Edo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/cCtp-lLsnEE",
        "filename": "EDO_Edo_Nigeria_PICSVAR1_Final",
        "files": [
            "EDO_Edo_Nigeria_PICSVAR1_Final.3gp",
            "EDO_Edo_Nigeria_PICSVAR1_Final.mov",
            "EDO_Edo_Nigeria_PICSVAR1_Final.mp4",
            "EDO_Edo_Nigeria_PICSVAR1_Final_Light.3gp",
            "EDO_Edo_Nigeria_PICSVAR1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-17 09:41:11"
    },
    "1174": {
        "id": "1174",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Efik",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/rnB4EZCaJto",
        "filename": "EFI_Efik_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "EFI_Efik_Nigeria_CoronavirusMarketplace_Final.3gp",
            "EFI_Efik_Nigeria_CoronavirusMarketplace_Final.mov",
            "EFI_Efik_Nigeria_CoronavirusMarketplace_Final.mp4",
            "EFI_Efik_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "EFI_Efik_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-01 09:31:47"
    },
    "1202": {
        "id": "1202",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Efik",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/Twxdcbej0-U",
        "filename": "EFI_Efik_Nigeria_CoronavirusMarketManager_Final",
        "files": [
            "EFI_Efik_Nigeria_CoronavirusMarketManager_Final.3gp",
            "EFI_Efik_Nigeria_CoronavirusMarketManager_Final.mov",
            "EFI_Efik_Nigeria_CoronavirusMarketManager_Final.mp4",
            "EFI_Efik_Nigeria_CoronavirusMarketManager_Final_Light.3gp",
            "EFI_Efik_Nigeria_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-29 15:10:17"
    },
    "1279": {
        "id": "1279",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Efik",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/PdIE8_nOl9A",
        "filename": "EFI_Efik_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "EFI_Efik_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "EFI_Efik_Nigeria_CoronavirusMaskUsage_Final.mov",
            "EFI_Efik_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "EFI_Efik_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "EFI_Efik_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-18 09:03:03"
    },
    "1337": {
        "id": "1337",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Efik",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/yYZtcuFWqcA",
        "filename": "EFI_Efik_Nigeria_PICSVar1_Final",
        "files": [
            "EFI_Efik_Nigeria_PICSVar1_Final.3gp",
            "EFI_Efik_Nigeria_PICSVar1_Final.mov",
            "EFI_Efik_Nigeria_PICSVar1_Final.mp4",
            "EFI_Efik_Nigeria_PICSVar1_Final_Light.3gp",
            "EFI_Efik_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 11:58:26"
    },
    "1372": {
        "id": "1372",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Efik",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/1E3DyMFoiNU",
        "filename": "EFIK_Efik_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "EFIK_Efik_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "EFIK_Efik_Nigeria_CoronavirusPublicTransportation_Final_.mov",
            "EFIK_Efik_Nigeria_CoronavirusPublicTransportation_Final_.mp4",
            "EFIK_Efik_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "EFIK_Efik_Nigeria_CoronavirusPublicTransportation_Final__Lite.mp4"
        ],
        "publishDate": "2021-10-10 18:23:15"
    },
    "1134": {
        "id": "1134",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Embu",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/oeo4jUQ_vfQ",
        "filename": "EBU_Embu_Kenya_PHLJerrycanFTF_Final",
        "files": [
            "EBU_Embu_Kenya_PHLJerrycanFTF_Final.3gp",
            "EBU_Embu_Kenya_PHLJerrycanFTF_Final.mov",
            "EBU_Embu_Kenya_PHLJerrycanFTF_Final.mp4",
            "EBU_Embu_Kenya_PHLJerrycanFTF_Final_Light.3gp",
            "EBU_Embu_Kenya_PHLJerrycanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-23 16:47:59"
    },
    "1379": {
        "id": "1379",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Embu",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/cFWS3V1Ycjo",
        "filename": "EBU_Embu_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "EBU_Embu_Kenya_CoronavirusMarketShopper_Final.3gp",
            "EBU_Embu_Kenya_CoronavirusMarketShopper_Final.mov",
            "EBU_Embu_Kenya_CoronavirusMarketShopper_Final.mp4",
            "EBU_Embu_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "EBU_Embu_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-12 20:20:10"
    },
    "1382": {
        "id": "1382",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Embu",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/aTI67QoQ6NQ",
        "filename": "EBU_Embu_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "EBU_Embu_Kenya_CoronavirusMarketManager_Final.3gp",
            "EBU_Embu_Kenya_CoronavirusMarketManager_Final.mov",
            "EBU_Embu_Kenya_CoronavirusMarketManager_Final.mp4",
            "EBU_Embu_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "EBU_Embu_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-12 21:07:19"
    },
    "1405": {
        "id": "1405",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Embu",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/I7APsfj_pes",
        "filename": "EBU_Embu_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "EBU_Embu_Kenya_CoronavirusMaskUsage_Final.3gp",
            "EBU_Embu_Kenya_CoronavirusMaskUsage_Final.mov",
            "EBU_Embu_Kenya_CoronavirusMaskUsage_Final.mp4",
            "EBU_Embu_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "EBU_Embu_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-19 11:39:31"
    },
    "1412": {
        "id": "1412",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Embu",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/-xusuR3v8fE",
        "filename": "EBU_Embu__Kenya_PICSVar1_Final",
        "files": [
            "EBU_Embu__Kenya_PICSVar1_Final.3gp",
            "EBU_Embu__Kenya_PICSVar1_Final.mov",
            "EBU_Embu__Kenya_PICSVar1_Final.mp4",
            "EBU_Embu__Kenya_PICSVar1_Final_Light.3gp",
            "EBU_Embu__Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-28 14:47:10"
    },
    "1114": {
        "id": "1114",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/UI0I_Em5efE",
        "filename": "EN_English_USA_CoronavirusMarketplace_Final",
        "files": [
            "EN_English_USA_CoronavirusMarketplace_Final.3gp",
            "EN_English_USA_CoronavirusMarketplace_Final.mov",
            "EN_English_USA_CoronavirusMarketplace_Final.mp4",
            "EN_English_USA_CoronavirusMarketplace_Final_Light.3gp",
            "EN_English_USA_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-05 20:20:50"
    },
    "1158": {
        "id": "1158",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/8hP1Rfobzxg",
        "filename": "EN_English_USA_CoronavirusMarketShopper_Final",
        "files": [
            "EN_English_USA_CoronavirusMarketShopper_Final.mov",
            "EN_English_USA_CoronavirusMarketShopper_Final.mp4",
            "EN_English_USA_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-21 21:29:34"
    },
    "1161": {
        "id": "1161",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "COVID-19 Pandemic: Marketplace - Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/laiwGVvJJiE",
        "filename": "EN_English_USA_CoronavirusMarketManager_Final",
        "files": [
            "EN_English_USA_CoronavirusMarketManager_Final.mov",
            "EN_English_USA_CoronavirusMarketManager_Final.mp4",
            "EN_English_USA_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-21 21:41:08"
    },
    "1242": {
        "id": "1242",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/P61KJP7N_dI",
        "filename": "EN_English_USA_CoronavirusMaskUsage_Final",
        "files": [
            "EN_English_USA_CoronavirusMaskUsage_Final.3gp",
            "EN_English_USA_CoronavirusMaskUsage_Final.mov",
            "EN_English_USA_CoronavirusMaskUsage_Final.mp4",
            "EN_English_USA_CoronavirusMaskUsage_Final_Light.3gp",
            "EN_English_USA_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-06-17 21:34:37"
    },
    "1245": {
        "id": "1245",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/7DvcKXPLjCs",
        "filename": "EN_English_USA_PICSVar1_Final",
        "files": [
            "EN_English_USA_PICSVar1_Final.3gp",
            "EN_English_USA_PICSVar1_Final.mov",
            "EN_English_USA_PICSVar1_Final.mp4",
            "EN_English_USA_PICSVar1_Final_Light.3gp",
            "EN_English_USA_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-07-14 21:38:53"
    },
    "1283": {
        "id": "1283",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/h9Vy7ZtAQcc",
        "filename": "EN_English_USA_CoronavirusPublicTransportation_Final",
        "files": [
            "EN_English_USA_CoronavirusPublicTransportation_Final.3gp",
            "EN_English_USA_CoronavirusPublicTransportation_Final.mov",
            "EN_English_USA_CoronavirusPublicTransportation_Final.mp4",
            "EN_English_USA_CoronavirusPublicTransportation_Final_Light.3gp",
            "EN_English_USA_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-20 13:40:51"
    },
    "1284": {
        "id": "1284",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Properly Using Facemasks - Ghana Version",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/hYJqd1txAOQ",
        "filename": "EN_English_USA_CoronavirusMaskUsageVar2_Final",
        "files": [
            "EN_English_USA_CoronavirusMaskUsageVar2_Final.3gp",
            "EN_English_USA_CoronavirusMaskUsageVar2_Final.mov",
            "EN_English_USA_CoronavirusMaskUsageVar2_Final.mp4",
            "EN_English_USA_CoronavirusMaskUsageVar2_Final_Light.3gp",
            "EN_English_USA_CoronavirusMaskUsageVar2_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-22 08:56:53"
    },
    "1304": {
        "id": "1304",
        "topic": "CoronavirusMaskUsageVar3",
        "category": "Health",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Properly Using Facemasks - Bangladesh Version",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This animation is a variant for Bangladesh.",
        "custom": null,
        "url": "//www.youtube.com/embed/kGeQJ5KZ6hw",
        "filename": "EN_English_USA_CoronavirusMaskUsageVar3_Final",
        "files": [
            "EN_English_USA_CoronavirusMaskUsageVar3_Final.3gp",
            "EN_English_USA_CoronavirusMaskUsageVar3_Final.mov",
            "EN_English_USA_CoronavirusMaskUsageVar3_Final.mp4",
            "EN_English_USA_CoronavirusMaskUsageVar3_Final_Light.3gp",
            "EN_English_USA_CoronavirusMaskUsageVar3_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-07 22:53:54"
    },
    "1316": {
        "id": "1316",
        "topic": "TripleSVar1",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Sweet Potato Roots for Timely Planting Material: The Triple S Method on How to Prepare and Store Roots (COVID Disclaimer)",
        "description": "This animation explains the Triple S Method for preparing and storing root vegetables.",
        "custom": null,
        "url": "//www.youtube.com/embed/MJn1XgLvyg0",
        "filename": "EN_English_USA_TripleSVar1_Final",
        "files": [
            "EN_English_USA_TripleSVar1_Final.3gp",
            "EN_English_USA_TripleSVar1_Final.mov",
            "EN_English_USA_TripleSVar1_Final.mp4",
            "EN_English_USA_TripleSVar1_Final_High.mp4",
            "EN_English_USA_TripleSVar1_Final_Light.3gp",
            "EN_English_USA_TripleSVar1_Final_High_Lite.mp4",
            "EN_English_USA_TripleSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-15 22:28:57"
    },
    "1416": {
        "id": "1416",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/AKZgJYSktbo",
        "filename": "EN_English_USA_CoronavirusNewcastleDiseaseVar1_Final",
        "files": [
            "EN_English_USA_CoronavirusNewcastleDiseaseVar1_Final.3gp",
            "EN_English_USA_CoronavirusNewcastleDiseaseVar1_Final.mov",
            "EN_English_USA_CoronavirusNewcastleDiseaseVar1_Final.mp4",
            "EN_English_USA_CoronavirusNewcastleDiseaseVar1_Final_Light.3gp",
            "EN_English_USA_CoronavirusNewcastleDiseaseVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-28 23:22:16"
    },
    "983": {
        "id": "983",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/MDaT-ky2cX4",
        "filename": "EN_English_USA_PHLJerryCanFTF_FemaleVoice_Final",
        "files": [
            "EN_English_USA_PHLJerryCanFTF_FemaleVoice_Final.3gp",
            "EN_English_USA_PHLJerryCanFTF_FemaleVoice_Final.mov",
            "EN_English_USA_PHLJerryCanFTF_FemaleVoice_Final.mp4",
            "EN_English_USA_PHLJerryCanFTF_FemaleVoice_Final_Light.3gp",
            "EN_English_USA_PHLJerryCanFTF_FemaleVoice_Final_Lite.mp4"
        ],
        "publishDate": "2020-09-01 16:31:14"
    },
    "984": {
        "id": "984",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": "Male Voice",
        "url": "//www.youtube.com/embed/puzJJNpUWeA",
        "filename": "EN_English_USA_PHLJerryCanFTF_MaleVoice_Final",
        "files": [
            "EN_English_USA_PHLJerryCanFTF_MaleVoice_Final.3gp",
            "EN_English_USA_PHLJerryCanFTF_MaleVoice_Final.mov",
            "EN_English_USA_PHLJerryCanFTF_MaleVoice_Final.mp4",
            "EN_English_USA_PHLJerryCanFTF_MaleVoice_Final_Light.3gp",
            "EN_English_USA_PHLJerryCanFTF_MaleVoice_Final_Lite.mp4"
        ],
        "publishDate": "2020-09-01 17:01:50"
    },
    "1111": {
        "id": "1111",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "English",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/BZtUSs4vEcc",
        "filename": "EN1_English_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "EN1_English_Nigeria_PHLJerryCanFTF_Final.3gp",
            "EN1_English_Nigeria_PHLJerryCanFTF_Final.mov",
            "EN1_English_Nigeria_PHLJerryCanFTF_Final.mp4",
            "EN1_English_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "EN1_English_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-02 10:00:07"
    },
    "1157": {
        "id": "1157",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "English",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": "Male Voice",
        "url": "//www.youtube.com/embed/fNpb7NjOFz0",
        "filename": "EN1_English_Nigeria_CoronavirusMarketplace_MaleVoice_Final",
        "files": [
            "EN1_English_Nigeria_CoronavirusMarketplace_MaleVoice_Final.3gp",
            "EN1_English_Nigeria_CoronavirusMarketplace_MaleVoice_Final.mov",
            "EN1_English_Nigeria_CoronavirusMarketplace_MaleVoice_Final.mp4",
            "EN1_English_Nigeria_CoronavirusMarketplace_MaleVoice_Final_Light.3gp",
            "EN1_English_Nigeria_CoronavirusMarketplace_MaleVoice_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-19 08:23:18"
    },
    "1166": {
        "id": "1166",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "English",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/XQ8Tcx4ob5I",
        "filename": "EN1_English_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "EN1_English_Nigeria_CoronavirusMarketplace_Final.3gp",
            "EN1_English_Nigeria_CoronavirusMarketplace_Final.mov",
            "EN1_English_Nigeria_CoronavirusMarketplace_Final.mp4",
            "EN1_English_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "EN1_English_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-26 11:53:36"
    },
    "1237": {
        "id": "1237",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "English",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/rnz0fQDqnc4",
        "filename": "EN1_English_Nigeria_CoronavirusMarketShopper_Final",
        "files": [
            "EN1_English_Nigeria_CoronavirusMarketShopper_Final.3gp",
            "EN1_English_Nigeria_CoronavirusMarketShopper_Final.mov",
            "EN1_English_Nigeria_CoronavirusMarketShopper_Final.mp4",
            "EN1_English_Nigeria_CoronavirusMarketShopper_Final_Light.3gp",
            "EN1_English_Nigeria_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-06-09 12:16:25"
    },
    "1239": {
        "id": "1239",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "English",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: Marketplace - Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/-PCFdZcFoHk",
        "filename": "EN1_English_Nigeria_CoronavirusMarketManager_Final",
        "files": [
            "EN1_English_Nigeria_CoronavirusMarketManager_Final.3gp",
            "EN1_English_Nigeria_CoronavirusMarketManager_Final.mov",
            "EN1_English_Nigeria_CoronavirusMarketManager_Final.mp4",
            "EN1_English_Nigeria_CoronavirusMarketManager_Final_Light.3gp",
            "EN1_English_Nigeria_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-06-10 07:35:45"
    },
    "1281": {
        "id": "1281",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "English",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/mlsKJWfqvas",
        "filename": "EN1_English_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "EN1_English_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "EN1_English_Nigeria_CoronavirusMaskUsage_Final.mov",
            "EN1_English_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "EN1_English_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "EN1_English_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-18 09:37:40"
    },
    "1314": {
        "id": "1314",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "English",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/VRuq3TLNmb0",
        "filename": "EN10_English_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "EN10_English_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "EN10_English_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "EN10_English_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "EN10_English_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "EN10_English_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-15 10:57:28"
    },
    "1113": {
        "id": "1113",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/lymS2eVdeUQ",
        "filename": "EN13_English_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "EN13_English_Kenya_PHLJerryCanFTF_Final.3gp",
            "EN13_English_Kenya_PHLJerryCanFTF_Final.mov",
            "EN13_English_Kenya_PHLJerryCanFTF_Final.mp4",
            "EN13_English_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "EN13_English_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-04 09:51:02"
    },
    "1124": {
        "id": "1124",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/MDWXnCMHQ2U",
        "filename": "EN13_English_Kenya_CoronavirusMarketplace_Final",
        "files": [
            "EN13_English_Kenya_CoronavirusMarketplace_Final.3gp",
            "EN13_English_Kenya_CoronavirusMarketplace_Final.mov",
            "EN13_English_Kenya_CoronavirusMarketplace_Final.mp4",
            "EN13_English_Kenya_CoronavirusMarketplace_Final_Light.3gp",
            "EN13_English_Kenya_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-17 12:13:33"
    },
    "1181": {
        "id": "1181",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID-19 Pandemic: Marketplace - Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/KIk0us7HOyw",
        "filename": "EN10_English_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "EN10_English_Kenya_CoronavirusMarketManager_Final.3gp",
            "EN10_English_Kenya_CoronavirusMarketManager_Final.mov",
            "EN10_English_Kenya_CoronavirusMarketManager_Final.mp4",
            "EN10_English_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "EN10_English_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-16 09:01:53"
    },
    "1219": {
        "id": "1219",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/1lnj9jAHCak",
        "filename": "EN10-English_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "EN10-English_Kenya_CoronavirusMarketShopper_Final.3gp",
            "EN10-English_Kenya_CoronavirusMarketShopper_Final.mov",
            "EN10-English_Kenya_CoronavirusMarketShopper_Final.mp4",
            "EN10-English_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "EN10-English_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-10 12:25:33"
    },
    "1330": {
        "id": "1330",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/hc6N4Dfx-jY",
        "filename": "EN1_English-Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "EN1_English-Kenya_CoronavirusPublicTransportation_Final.3gp",
            "EN1_English-Kenya_CoronavirusPublicTransportation_Final.mov",
            "EN1_English-Kenya_CoronavirusPublicTransportation_Final.mp4",
            "EN1_English-Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "EN1_English-Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 09:19:17"
    },
    "1341": {
        "id": "1341",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/PnNN6Jcds8w",
        "filename": "EN10-English_Kenya_PICSVar1_Final",
        "files": [
            "EN10-English_Kenya_PICSVar1_Final.3gp",
            "EN10-English_Kenya_PICSVar1_Final.mov",
            "EN10-English_Kenya_PICSVar1_Final.mp4",
            "EN10-English_Kenya_PICSVar1_Final_Light.3gp",
            "EN10-English_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-24 10:28:56"
    },
    "1455": {
        "id": "1455",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/Em666RHXj2o",
        "filename": "EN10_English_Keyna_CoronavirusMaskUsage_Final",
        "files": [
            "EN10_English_Keyna_CoronavirusMaskUsage_Final.3gp",
            "EN10_English_Keyna_CoronavirusMaskUsage_Final.mov",
            "EN10_English_Keyna_CoronavirusMaskUsage_Final.mp4",
            "EN10_English_Keyna_CoronavirusMaskUsage_Final_Light.3gp",
            "EN10_English_Keyna_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-22 08:25:01"
    },
    "1461": {
        "id": "1461",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/QCw7HUwd3c4",
        "filename": "EN10_English_Kenya_CoronavirusNewcastleDisease_Final",
        "files": [
            "EN10_English_Kenya_CoronavirusNewcastleDisease_Final.3gp",
            "EN10_English_Kenya_CoronavirusNewcastleDisease_Final.mov",
            "EN10_English_Kenya_CoronavirusNewcastleDisease_Final.mp4",
            "EN10_English_Kenya_CoronavirusNewcastleDisease_Final_Light.3gp",
            "EN10_English_Kenya_CoronavirusNewcastleDisease_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-23 10:56:03"
    },
    "1508": {
        "id": "1508",
        "topic": "TripleSVar1",
        "category": "Agriculture",
        "language": "English",
        "country": "Kenya",
        "subtitles": null,
        "title": "Sweet Potato Roots for Timely Planting Material: The Triple S Method on How to Prepare and Store Roots (COVID Disclaimer)",
        "description": "This animation explains the Triple S Method for preparing and storing root vegetables.",
        "custom": null,
        "url": "//www.youtube.com/embed/Ntsi9u1rUMI",
        "filename": "EN10_English_Kenya_TripleS_COVID_Final",
        "files": [
            "EN10_English_Kenya_TripleS_COVID_Final.3gp",
            "EN10_English_Kenya_TripleS_COVID_Final.mov",
            "EN10_English_Kenya_TripleS_COVID_Final.mp4",
            "EN10_English_Kenya_TripleS_COVID_Final_Light.3gp",
            "EN10_English_Kenya_TripleS_COVID_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-10 21:01:24"
    },
    "1142": {
        "id": "1142",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/ili6OCz4hMM",
        "filename": "EN10_English_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "EN10_English_Ghana_CoronavirusMarketplace_Final.3gp",
            "EN10_English_Ghana_CoronavirusMarketplace_Final.mov",
            "EN10_English_Ghana_CoronavirusMarketplace_Final.mp4",
            "EN10_English_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "EN10_English_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-10 11:45:40"
    },
    "1238": {
        "id": "1238",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: Marketplace - Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/gG9AdQL2kQQ",
        "filename": "EN10_English_Ghana_CoronavirusMarketManager_Final",
        "files": [
            "EN10_English_Ghana_CoronavirusMarketManager_Final.3gp",
            "EN10_English_Ghana_CoronavirusMarketManager_Final.mov",
            "EN10_English_Ghana_CoronavirusMarketManager_Final.mp4",
            "EN10_English_Ghana_CoronavirusMarketManager_Final_Light.3gp",
            "EN10_English_Ghana_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-06-10 07:19:16"
    },
    "1240": {
        "id": "1240",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace ",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/-SzLlyBcm9s",
        "filename": "EN10_English_Ghana_CoronavirusMarketShopper_Final",
        "files": [
            "EN10_English_Ghana_CoronavirusMarketShopper_Final.3gp",
            "EN10_English_Ghana_CoronavirusMarketShopper_Final.mov",
            "EN10_English_Ghana_CoronavirusMarketShopper_Final.mp4",
            "EN10_English_Ghana_CoronavirusMarketShopper_Final_Light.3gp",
            "EN10_English_Ghana_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-06-10 10:14:03"
    },
    "1344": {
        "id": "1344",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/siledjBPCOc",
        "filename": "EN10_English_Ghana_CoronavirusMaskUsageVar2_Final",
        "files": [
            "EN10_English_Ghana_CoronavirusMaskUsageVar2_Final.3gp",
            "EN10_English_Ghana_CoronavirusMaskUsageVar2_Final.mov",
            "EN10_English_Ghana_CoronavirusMaskUsageVar2_Final.mp4",
            "EN10_English_Ghana_CoronavirusMaskUsageVar2_Final_Light.3gp",
            "EN10_English_Ghana_CoronavirusMaskUsageVar2_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-27 09:35:15"
    },
    "1440": {
        "id": "1440",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/lObiDp2_NvA",
        "filename": "EN10_English_Ghana_PICSVar1_Final",
        "files": [
            "EN10_English_Ghana_PICSVar1_Final.3gp",
            "EN10_English_Ghana_PICSVar1_Final.mov",
            "EN10_English_Ghana_PICSVar1_Final.mp4",
            "EN10_English_Ghana_PICSVar1_Final_Light.3gp",
            "EN10_English_Ghana_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-09 10:44:58"
    },
    "1462": {
        "id": "1462",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/MZf2fXITyU8",
        "filename": "EN10_English_Ghana_CoronavirusNewcastleDisease_Final",
        "files": [
            "EN10_English_Ghana_CoronavirusNewcastleDisease_Final.3gp",
            "EN10_English_Ghana_CoronavirusNewcastleDisease_Final.mov",
            "EN10_English_Ghana_CoronavirusNewcastleDisease_Final.mp4",
            "EN10_English_Ghana_CoronavirusNewcastleDisease_Final_Light.3gp",
            "EN10_English_Ghana_CoronavirusNewcastleDisease_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-23 11:24:51"
    },
    "980": {
        "id": "980",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": "Male Voice",
        "url": "//www.youtube.com/embed/9Hc2TE_SA1A",
        "filename": "EN10_English_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "EN10_English_Ghana_PHLJerryCanFTF_Final.3gp",
            "EN10_English_Ghana_PHLJerryCanFTF_Final.mov",
            "EN10_English_Ghana_PHLJerryCanFTF_Final.mp4",
            "EN10_English_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "EN10_English_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-09-01 15:32:25"
    },
    "981": {
        "id": "981",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/-hZ6sK1oETU",
        "filename": "EN10_English_Ghana_PHLJerryCanFTF_FemaleVoice_Final",
        "files": [
            "EN10_English_Ghana_PHLJerryCanFTF_FemaleVoice_Final.3gp",
            "EN10_English_Ghana_PHLJerryCanFTF_FemaleVoice_Final.mov",
            "EN10_English_Ghana_PHLJerryCanFTF_FemaleVoice_Final.mp4",
            "EN10_English_Ghana_PHLJerryCanFTF_FemaleVoice_Final_Light.3gp",
            "EN10_English_Ghana_PHLJerryCanFTF_FemaleVoice_Final_Lite.mp4"
        ],
        "publishDate": "2020-09-01 16:07:36"
    },
    "1159": {
        "id": "1159",
        "topic": "CoronavirusMarketShopperVar1",
        "category": "Health",
        "language": "English",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace Variant for Bangladesh",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic. This is a variant animation for Bangladesh.",
        "custom": null,
        "url": "//www.youtube.com/embed/ZyOi7IzbqCU",
        "filename": "EN14_English_Bangladesh_CoronavirusMarketShopperVar1_Final",
        "files": [
            "EN14_English_Bangladesh_CoronavirusMarketShopperVar1_Final.3gp",
            "EN14_English_Bangladesh_CoronavirusMarketShopperVar1_Final.mov",
            "EN14_English_Bangladesh_CoronavirusMarketShopperVar1_Final.mp4",
            "EN14_English_Bangladesh_CoronavirusMarketShopperVar1_Final_Light.3gp",
            "EN14_English_Bangladesh_CoronavirusMarketShopperVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-21 21:31:01"
    },
    "1247": {
        "id": "1247",
        "topic": "PICSVar3",
        "category": "Agriculture",
        "language": "English",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/CFMf3fkRCMY",
        "filename": "EN11_English_Bangladesh_PICSVar3_Final",
        "files": [
            "EN11_English_Bangladesh_PICSVar3_Final.3gp",
            "EN11_English_Bangladesh_PICSVar3_Final.mov",
            "EN11_English_Bangladesh_PICSVar3_Final.mp4",
            "EN11_English_Bangladesh_PICSVar3_Final_Light.3gp",
            "EN11_English_Bangladesh_PICSVar3_Final_Lite.mp4"
        ],
        "publishDate": "2021-07-14 21:42:44"
    },
    "1303": {
        "id": "1303",
        "topic": "CoronavirusMarketplaceVar1",
        "category": "Health",
        "language": "English",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "How to Sell Safely in the Marketplace during the Coronavirus Pandemic - Variant for Bangladesh",
        "description": "The arrival of the Coronavirus changed many parts of community life. Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic. This animation is a variant for Bangladesh.",
        "custom": null,
        "url": "//www.youtube.com/embed/vA3y-D5ae-Q",
        "filename": "EN11_English_Bangladesh_CoronavirusMarketplaceVar1_Final",
        "files": [
            "EN11_English_Bangladesh_CoronavirusMarketplaceVar1_Final.3gp",
            "EN11_English_Bangladesh_CoronavirusMarketplaceVar1_Final.mov",
            "EN11_English_Bangladesh_CoronavirusMarketplaceVar1_Final.mp4",
            "EN11_English_Bangladesh_CoronavirusMarketplaceVar1_Final_Light.3gp",
            "EN11_English_Bangladesh_CoronavirusMarketplaceVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-07 22:40:54"
    },
    "1309": {
        "id": "1309",
        "topic": "CoronavirusMaskUsageVar3",
        "category": "Health",
        "language": "English",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "Properly Using Facemasks - Bangladesh Version",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This animation is a variant for Bangladesh.",
        "custom": null,
        "url": "//www.youtube.com/embed/L4VeTek3YJM",
        "filename": "EN14_English_Bangladesh_CoronavirusMaskUsageVar3_Final",
        "files": [
            "EN14_English_Bangladesh_CoronavirusMaskUsageVar3_Final.3gp",
            "EN14_English_Bangladesh_CoronavirusMaskUsageVar3_Final.mov",
            "EN14_English_Bangladesh_CoronavirusMaskUsageVar3_Final.mp4",
            "EN14_English_Bangladesh_CoronavirusMaskUsageVar3_Final_Light.3gp",
            "EN14_English_Bangladesh_CoronavirusMaskUsageVar3_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-09 22:30:16"
    },
    "1325": {
        "id": "1325",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "English",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/R-YWyrdTymQ",
        "filename": "EN10_English_Bangladesh_CoronavirusPublicTransportation_Final",
        "files": [
            "EN10_English_Bangladesh_CoronavirusPublicTransportation_Final.3gp",
            "EN10_English_Bangladesh_CoronavirusPublicTransportation_Final.mov",
            "EN10_English_Bangladesh_CoronavirusPublicTransportation_Final.mp4",
            "EN10_English_Bangladesh_CoronavirusPublicTransportation_Final_Light.3gp",
            "EN10_English_Bangladesh_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-19 19:34:46"
    },
    "1467": {
        "id": "1467",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "English",
        "country": "Bangladesh",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/ndHz60YX62Y",
        "filename": "EN14-English_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final",
        "files": [
            "EN14-English_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final.3gp",
            "EN14-English_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final.mov",
            "EN14-English_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final.mp4",
            "EN14-English_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final_Light.3gp",
            "EN14-English_Bangladesh_CoronavirusNewcastleDiseaseVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-30 09:15:02"
    },
    "1200": {
        "id": "1200",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Ewe",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/HbNH1g_UTmw",
        "filename": "EWE_Ewe_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "EWE_Ewe_Ghana_CoronavirusMarketplace_Final.3gp",
            "EWE_Ewe_Ghana_CoronavirusMarketplace_Final.mov",
            "EWE_Ewe_Ghana_CoronavirusMarketplace_Final.mp4",
            "EWE_Ewe_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "EWE_Ewe_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-29 13:17:22"
    },
    "1298": {
        "id": "1298",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "Ewe",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/wH4bddRPqZg",
        "filename": "EWE_Ewe_Ghana_CoronavirusMaskUsage_Final",
        "files": [
            "EWE_Ewe_Ghana_CoronavirusMaskUsage_Final.3gp",
            "EWE_Ewe_Ghana_CoronavirusMaskUsage_Final.mov",
            "EWE_Ewe_Ghana_CoronavirusMaskUsage_Final.mp4",
            "EWE_Ewe_Ghana_CoronavirusMaskUsage_Final_Light.3gp",
            "EWE_Ewe_Ghana_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-07 11:47:30"
    },
    "1308": {
        "id": "1308",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Ewe",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/S0uv8FUN4DQ",
        "filename": "EWE_Ewe_Ghana_PICSVar1_Final",
        "files": [
            "EWE_Ewe_Ghana_PICSVar1_Final.3gp",
            "EWE_Ewe_Ghana_PICSVar1_Final.mov",
            "EWE_Ewe_Ghana_PICSVar1_Final.mp4",
            "EWE_Ewe_Ghana_PICSVar1_Final_Light.3gp",
            "EWE_Ewe_Ghana_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-08 13:19:20"
    },
    "963": {
        "id": "963",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Ewe",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/pml6tp8hXbI",
        "filename": "EWE_Ewe_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "EWE_Ewe_Ghana_PHLJerryCanFTF_Final.3gp",
            "EWE_Ewe_Ghana_PHLJerryCanFTF_Final.mov",
            "EWE_Ewe_Ghana_PHLJerryCanFTF_Final.mp4",
            "EWE_Ewe_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "EWE_Ewe_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-27 13:38:28"
    },
    "1171": {
        "id": "1171",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Fante",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/nzS8lrlyWag",
        "filename": "AKA1_Fante_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "AKA1_Fante_Ghana_CoronavirusMarketplace_Final.3gp",
            "AKA1_Fante_Ghana_CoronavirusMarketplace_Final.mov",
            "AKA1_Fante_Ghana_CoronavirusMarketplace_Final.mp4",
            "AKA1_Fante_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "AKA1_Fante_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-31 11:35:20"
    },
    "1223": {
        "id": "1223",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Fante",
        "country": "Ghana",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/nR0hZEN9CKU",
        "filename": "AKA1_Fante_Ghana_CoronavirusMarketManager_Final",
        "files": [
            "AKA1_Fante_Ghana_CoronavirusMarketManager_Final.3gp",
            "AKA1_Fante_Ghana_CoronavirusMarketManager_Final.mov",
            "AKA1_Fante_Ghana_CoronavirusMarketManager_Final.mp4",
            "AKA1_Fante_Ghana_CoronavirusMarketManager_Final_Light.3gp",
            "AKA1_Fante_Ghana_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-13 09:27:01"
    },
    "1355": {
        "id": "1355",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Fante",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/sX6px25Ptd0",
        "filename": "AKA1_Fante_Ghana_CoronavirusPublicTransportation_Final",
        "files": [
            "AKA1_Fante_Ghana_CoronavirusPublicTransportation_Final.3gp",
            "AKA1_Fante_Ghana_CoronavirusPublicTransportation_Final.mov",
            "AKA1_Fante_Ghana_CoronavirusPublicTransportation_Final.mp4",
            "AKA1_Fante_Ghana_CoronavirusPublicTransportation_Final_Light.3gp",
            "AKA1_Fante_Ghana_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-29 12:47:09"
    },
    "1401": {
        "id": "1401",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Fante",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/7qqswY_6Sck",
        "filename": "AKA1_Fante_Ghana_CoronavirusMarketShopper_Final",
        "files": [
            "AKA1_Fante_Ghana_CoronavirusMarketShopper_Final.3gp",
            "AKA1_Fante_Ghana_CoronavirusMarketShopper_Final.mov",
            "AKA1_Fante_Ghana_CoronavirusMarketShopper_Final.mp4",
            "AKA1_Fante_Ghana_CoronavirusMarketShopper_Final_Light.3gp",
            "AKA1_Fante_Ghana_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-18 10:38:25"
    },
    "947": {
        "id": "947",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Fante",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/cPHjMaZZtk0",
        "filename": "AKA1_Fante_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "AKA1_Fante_Ghana_PHLJerryCanFTF_Final.3gp",
            "AKA1_Fante_Ghana_PHLJerryCanFTF_Final.mov",
            "AKA1_Fante_Ghana_PHLJerryCanFTF_Final.mp4",
            "AKA1_Fante_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "AKA1_Fante_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-09 17:03:38"
    },
    "1465": {
        "id": "1465",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Farefare",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/NLfPEZoEof8",
        "filename": "GUR_Farefare_Ghana_CoronavirusNewcastleDisease_Final",
        "files": [
            "GUR_Farefare_Ghana_CoronavirusNewcastleDisease_Final.3gp",
            "GUR_Farefare_Ghana_CoronavirusNewcastleDisease_Final.mov",
            "GUR_Farefare_Ghana_CoronavirusNewcastleDisease_Final.mp4",
            "GUR_Farefare_Ghana_CoronavirusNewcastleDisease_Final_Light.3gp",
            "GUR_Farefare_Ghana_CoronavirusNewcastleDisease_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-23 12:50:23"
    },
    "1470": {
        "id": "1470",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "Farefare",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/XMx876xV2Zc",
        "filename": "GUR_Farefare_Ghana_CoronavirusMaskUsageVar2_Final",
        "files": [
            "GUR_Farefare_Ghana_CoronavirusMaskUsageVar2_Final.3gp",
            "GUR_Farefare_Ghana_CoronavirusMaskUsageVar2_Final.mov",
            "GUR_Farefare_Ghana_CoronavirusMaskUsageVar2_Final.mp4",
            "GUR_Farefare_Ghana_CoronavirusMaskUsageVar2_Final_Light.3gp",
            "GUR_Farefare_Ghana_CoronavirusMaskUsageVar2_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-01 08:04:01"
    },
    "986": {
        "id": "986",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Farefare",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/NrHrkl5JCSs",
        "filename": "GUR_Farefare_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "GUR_Farefare_Ghana_PHLJerryCanFTF_Final.3gp",
            "GUR_Farefare_Ghana_PHLJerryCanFTF_Final.mov",
            "GUR_Farefare_Ghana_PHLJerryCanFTF_Final.mp4",
            "GUR_Farefare_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "GUR_Farefare_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-09-01 18:28:49"
    },
    "1313": {
        "id": "1313",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "French",
        "country": "France",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/qPK4L3bIcXg",
        "filename": "FR_French_France_CoronavirusPublicTransportation_Final",
        "files": [
            "FR_French_France_CoronavirusPublicTransportation_Final.3gp",
            "FR_French_France_CoronavirusPublicTransportation_Final.mov",
            "FR_French_France_CoronavirusPublicTransportation_Final.mp4",
            "FR_French_France_CoronavirusPublicTransportation_Final_Light.3gp",
            "FR_French_France_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-15 10:28:54"
    },
    "1386": {
        "id": "1386",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "French",
        "country": "France",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/LAkq9jXvFE8",
        "filename": "FR_French_France_PICSVar1_Final",
        "files": [
            "FR_French_France_PICSVar1_Final.3gp",
            "FR_French_France_PICSVar1_Final.mov",
            "FR_French_France_PICSVar1_Final.mp4",
            "FR_French_France_PICSVar1_Final_Light.3gp",
            "FR_French_France_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-14 13:20:35"
    },
    "1458": {
        "id": "1458",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "French",
        "country": "France",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/EJ-cl1fEgqY",
        "filename": "FR_French_France_CoronavirusMaskUsage_Final",
        "files": [
            "FR_French_France_CoronavirusMaskUsage_Final.3gp",
            "FR_French_France_CoronavirusMaskUsage_Final.mov",
            "FR_French_France_CoronavirusMaskUsage_Final.mp4",
            "FR_French_France_CoronavirusMaskUsage_Final_Light.3gp",
            "FR_French_France_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-22 09:27:49"
    },
    "1050": {
        "id": "1050",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Fulfulde",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/2xio0QmhZGI",
        "filename": "FUH2_Fulfulde_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "FUH2_Fulfulde_Nigeria_PHLJerryCanFTF_Final.3gp",
            "FUH2_Fulfulde_Nigeria_PHLJerryCanFTF_Final.mov",
            "FUH2_Fulfulde_Nigeria_PHLJerryCanFTF_Final.mp4",
            "FUH2_Fulfulde_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "FUH2_Fulfulde_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-04 08:45:29"
    },
    "1175": {
        "id": "1175",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Fulfulde",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/fxj4PM2Nf6w",
        "filename": "FUH2_Fulfulde_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketplace_Final.3gp",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketplace_Final.mov",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketplace_Final.mp4",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-01 09:36:52"
    },
    "1199": {
        "id": "1199",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Fulfulde",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/BjW_LCYo8fc",
        "filename": "FUH2_Fulfulde_Nigeria_CoronavirusMarketManager_Final",
        "files": [
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketManager_Final.3gp",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketManager_Final.mov",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketManager_Final.mp4",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketManager_Final_Light.3gp",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-28 10:45:25"
    },
    "1220": {
        "id": "1220",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Fulfulde",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/FacBQhi9WU8",
        "filename": "FUH2_Fulfulde_Nigeria_CoronavirusMarketShopper_Final",
        "files": [
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketShopper_Final.3gp",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketShopper_Final.mov",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketShopper_Final.mp4",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketShopper_Final_Light.3gp",
            "FUH2_Fulfulde_Nigeria_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-10 12:38:06"
    },
    "1272": {
        "id": "1272",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Fulfulde",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/gL5N-Y0ZK5M",
        "filename": "FUH2_Fulfulde_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "FUH2_Fulfulde_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "FUH2_Fulfulde_Nigeria_CoronavirusMaskUsage_Final.mov",
            "FUH2_Fulfulde_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "FUH2_Fulfulde_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "FUH2_Fulfulde_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-11 10:08:15"
    },
    "1338": {
        "id": "1338",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Fulfulde",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/r41WYNcAucQ",
        "filename": "FUH2_Fulfulde_Nigeria_PICSVar1_Final",
        "files": [
            "FUH2_Fulfulde_Nigeria_PICSVar1_Final.3gp",
            "FUH2_Fulfulde_Nigeria_PICSVar1_Final.mov",
            "FUH2_Fulfulde_Nigeria_PICSVar1_Final.mp4",
            "FUH2_Fulfulde_Nigeria_PICSVar1_Final_Light.3gp",
            "FUH2_Fulfulde_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 12:13:25"
    },
    "1196": {
        "id": "1196",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Ga",
        "country": "Ghana",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/dI8kYVaVFZQ",
        "filename": "GA_Ghana_CoronavirusMarketManager_Final",
        "files": [
            "GA_Ghana_CoronavirusMarketManager_Final.3gp",
            "GA_Ghana_CoronavirusMarketManager_Final.mov",
            "GA_Ghana_CoronavirusMarketManager_Final.mp4",
            "GA_Ghana_CoronavirusMarketManager_Final_Light.3gp",
            "GA_Ghana_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-27 13:07:49"
    },
    "1396": {
        "id": "1396",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Ga",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/I5CYut32T6k",
        "filename": "GA_Ga_Ghana_CoronavirusPublicTransportation_Final",
        "files": [
            "GA_Ga_Ghana_CoronavirusPublicTransportation_Final.3gp",
            "GA_Ga_Ghana_CoronavirusPublicTransportation_Final.mov",
            "GA_Ga_Ghana_CoronavirusPublicTransportation_Final.mp4",
            "GA_Ga_Ghana_CoronavirusPublicTransportation_Final_Light.3gp",
            "GA_Ga_Ghana_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-18 09:26:16"
    },
    "1404": {
        "id": "1404",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "Ga",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/zqcsTpMPVyQ",
        "filename": "GA_Ga_Ghana_CoronavirusMaskUsageVar2_Final",
        "files": [
            "GA_Ga_Ghana_CoronavirusMaskUsageVar2_Final.3gp",
            "GA_Ga_Ghana_CoronavirusMaskUsageVar2_Final.mov",
            "GA_Ga_Ghana_CoronavirusMaskUsageVar2_Final.mp4",
            "GA_Ga_Ghana_CoronavirusMaskUsageVar2_Final_Light.3gp",
            "GA_Ga_Ghana_CoronavirusMaskUsageVar2_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-19 11:08:52"
    },
    "1422": {
        "id": "1422",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Ga",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/CLn4UErGoz4",
        "filename": "GA_Ga_Ghana_PICSVar1_Final",
        "files": [
            "GA_Ga_Ghana_PICSVar1_Final.3gp",
            "GA_Ga_Ghana_PICSVar1_Final.mov",
            "GA_Ga_Ghana_PICSVar1_Final.mp4",
            "GA_Ga_Ghana_PICSVar1_Final_Light.3gp",
            "GA_Ga_Ghana_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-02 15:09:59"
    },
    "1464": {
        "id": "1464",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Ga",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/gHPuvy7qO0s",
        "filename": "GA_Ga_Ghana_CoronavirusNewcastleDisease_Final",
        "files": [
            "GA_Ga_Ghana_CoronavirusNewcastleDisease_Final.3gp",
            "GA_Ga_Ghana_CoronavirusNewcastleDisease_Final.mov",
            "GA_Ga_Ghana_CoronavirusNewcastleDisease_Final.mp4",
            "GA_Ga_Ghana_CoronavirusNewcastleDisease_Final_Light.3gp",
            "GA_Ga_Ghana_CoronavirusNewcastleDisease_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-23 12:44:18"
    },
    "964": {
        "id": "964",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Ga",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/QljdNCpcirI",
        "filename": "GA_Ga_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "GA_Ga_Ghana_PHLJerryCanFTF_Final.3gp",
            "GA_Ga_Ghana_PHLJerryCanFTF_Final.mov",
            "GA_Ga_Ghana_PHLJerryCanFTF_Final.mp4",
            "GA_Ga_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "GA_Ga_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-27 13:50:05"
    },
    "1116": {
        "id": "1116",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Giryama",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/ILcamDmWofY",
        "filename": "NYF_Giryama_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "NYF_Giryama_Kenya_PHLJerryCanFTF_Final.3gp",
            "NYF_Giryama_Kenya_PHLJerryCanFTF_Final.mov",
            "NYF_Giryama_Kenya_PHLJerryCanFTF_Final.mp4",
            "NYF_Giryama_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "NYF_Giryama_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-08 10:12:02"
    },
    "1211": {
        "id": "1211",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Giryama",
        "country": "Kenya",
        "subtitles": null,
        "title": "Market Place-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/BJuNP_JHWsw",
        "filename": "NYF_Giryama_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "NYF_Giryama_Kenya_CoronavirusMarketManager_Final.3gp",
            "NYF_Giryama_Kenya_CoronavirusMarketManager_Final.mov",
            "NYF_Giryama_Kenya_CoronavirusMarketManager_Final.mp4",
            "NYF_Giryama_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "NYF_Giryama_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-06 12:38:16"
    },
    "1229": {
        "id": "1229",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Giryama",
        "country": "Kenya",
        "subtitles": null,
        "title": "cOVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/LZxwSqfWT0Y",
        "filename": "NYF_Giryama_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "NYF_Giryama_Kenya_CoronavirusMarketShopper_Final.3gp",
            "NYF_Giryama_Kenya_CoronavirusMarketShopper_Final.mov",
            "NYF_Giryama_Kenya_CoronavirusMarketShopper_Final.mp4",
            "NYF_Giryama_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "NYF_Giryama_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-20 09:02:17"
    },
    "1367": {
        "id": "1367",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Giryama",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/JCsejsdpehg",
        "filename": "NYF_Giryama_Kenya_CoronavirusMaskUsageVar2_Final",
        "files": [
            "NYF_Giryama_Kenya_CoronavirusMaskUsageVar2_Final.3gp",
            "NYF_Giryama_Kenya_CoronavirusMaskUsageVar2_Final.mov",
            "NYF_Giryama_Kenya_CoronavirusMaskUsageVar2_Final.mp4",
            "NYF_Giryama_Kenya_CoronavirusMaskUsageVar2_Final_Light.3gp",
            "NYF_Giryama_Kenya_CoronavirusMaskUsageVar2_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-06 08:47:45"
    },
    "1397": {
        "id": "1397",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Giryama",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/_hr4AR2hTyo",
        "filename": "NYF_Giryama_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "NYF_Giryama_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "NYF_Giryama_Kenya_CoronavirusPublicTransportation_Final.mov",
            "NYF_Giryama_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "NYF_Giryama_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "NYF_Giryama_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-18 09:37:06"
    },
    "1441": {
        "id": "1441",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Giryama",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/lqVSBo4W2TE",
        "filename": "NYF_Giryama_Kenya_PICSVar1_Final",
        "files": [
            "NYF_Giryama_Kenya_PICSVar1_Final.3gp",
            "NYF_Giryama_Kenya_PICSVar1_Final.mov",
            "NYF_Giryama_Kenya_PICSVar1_Final.mp4",
            "NYF_Giryama_Kenya_PICSVar1_Final_Light.3gp",
            "NYF_Giryama_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-09 13:48:19"
    },
    "985": {
        "id": "985",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Gonja",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/NZOo1kPZVKk",
        "filename": "GJN_Gonja_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "GJN_Gonja_Ghana_PHLJerryCanFTF_Final.3gp",
            "GJN_Gonja_Ghana_PHLJerryCanFTF_Final.mov",
            "GJN_Gonja_Ghana_PHLJerryCanFTF_Final.mp4",
            "GJN_Gonja_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "GJN_Gonja_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-09-01 18:24:15"
    },
    "1115": {
        "id": "1115",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Gusii",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/n751kZbpimE",
        "filename": "GUZ_Gusii_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "GUZ_Gusii_Kenya_PHLJerryCanFTF_Final.3gp",
            "GUZ_Gusii_Kenya_PHLJerryCanFTF_Final.mov",
            "GUZ_Gusii_Kenya_PHLJerryCanFTF_Final.mp4",
            "GUZ_Gusii_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "GUZ_Gusii_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-08 08:45:18"
    },
    "1398": {
        "id": "1398",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Gusii",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace ",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/-yfY3NYsZlI",
        "filename": "GUZ_Gusii_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "GUZ_Gusii_Kenya_CoronavirusMarketShopper_Final.3gp",
            "GUZ_Gusii_Kenya_CoronavirusMarketShopper_Final.mov",
            "GUZ_Gusii_Kenya_CoronavirusMarketShopper_Final.mp4",
            "GUZ_Gusii_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "GUZ_Gusii_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-18 10:03:25"
    },
    "1408": {
        "id": "1408",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Gusii",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/BPkoI_KpHDw",
        "filename": "GUZ_Gusii_Kenya_PICSVar1_Final",
        "files": [
            "GUZ_Gusii_Kenya_PICSVar1_Final.3gp",
            "GUZ_Gusii_Kenya_PICSVar1_Final.mov",
            "GUZ_Gusii_Kenya_PICSVar1_Final.mp4",
            "GUZ_Gusii_Kenya_PICSVar1_Final_Light.3gp",
            "GUZ_Gusii_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-22 10:33:10"
    },
    "1048": {
        "id": "1048",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Hausa",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/z_R5HGZiiuE",
        "filename": "HA1_Hausa_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "HA1_Hausa_Nigeria_PHLJerryCanFTF_Final.3gp",
            "HA1_Hausa_Nigeria_PHLJerryCanFTF_Final.mov",
            "HA1_Hausa_Nigeria_PHLJerryCanFTF_Final.mp4",
            "HA1_Hausa_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "HA1_Hausa_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-04 08:16:19"
    },
    "1178": {
        "id": "1178",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Hausa",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/Wrp0_ogDyGg",
        "filename": "HA1_Hausa_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "HA1_Hausa_Nigeria_CoronavirusMarketplace_Final.3gp",
            "HA1_Hausa_Nigeria_CoronavirusMarketplace_Final.mov",
            "HA1_Hausa_Nigeria_CoronavirusMarketplace_Final.mp4",
            "HA1_Hausa_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "HA1_Hausa_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-05 09:11:07"
    },
    "1273": {
        "id": "1273",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Hausa",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/rF6CJ66PZ1o",
        "filename": "HS_Hausa_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "HS_Hausa_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "HS_Hausa_Nigeria_CoronavirusMaskUsage_Final.mov",
            "HS_Hausa_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "HS_Hausa_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "HS_Hausa_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-11 10:24:35"
    },
    "1324": {
        "id": "1324",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Hausa",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/SxizuRttIZs",
        "filename": "HS_Hausa_Nigeria_PICSVar1_Final",
        "files": [
            "HS_Hausa_Nigeria_PICSVar1_Final.3gp",
            "HS_Hausa_Nigeria_PICSVar1_Final.mov",
            "HS_Hausa_Nigeria_PICSVar1_Final.mp4",
            "HS_Hausa_Nigeria_PICSVar1_Final_Light.3gp",
            "HS_Hausa_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-17 14:16:28"
    },
    "1176": {
        "id": "1176",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Ibibio",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/g0dSaLxNkkw",
        "filename": "IBB_Ibibio_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "IBB_Ibibio_Nigeria_CoronavirusMarketplace_Final.3gp",
            "IBB_Ibibio_Nigeria_CoronavirusMarketplace_Final.mov",
            "IBB_Ibibio_Nigeria_CoronavirusMarketplace_Final.mp4",
            "IBB_Ibibio_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "IBB_Ibibio_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-01 12:56:26"
    },
    "1204": {
        "id": "1204",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Ibibio",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/7SmMNjIlT8c",
        "filename": "IBB_Ibibio_Nigeria_CoronavirusMarketManager_Final",
        "files": [
            "IBB_Ibibio_Nigeria_CoronavirusMarketManager_Final.3gp",
            "IBB_Ibibio_Nigeria_CoronavirusMarketManager_Final.mov",
            "IBB_Ibibio_Nigeria_CoronavirusMarketManager_Final.mp4",
            "IBB_Ibibio_Nigeria_CoronavirusMarketManager_Final_Light.3gp",
            "IBB_Ibibio_Nigeria_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-30 08:21:49"
    },
    "1215": {
        "id": "1215",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Ibibio",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/3NLx0qlfHlY",
        "filename": "IBB_Ibibio_Nigeria_CoronavirusMarketShopper_Final",
        "files": [
            "IBB_Ibibio_Nigeria_CoronavirusMarketShopper_Final.3gp",
            "IBB_Ibibio_Nigeria_CoronavirusMarketShopper_Final.mov",
            "IBB_Ibibio_Nigeria_CoronavirusMarketShopper_Final.mp4",
            "IBB_Ibibio_Nigeria_CoronavirusMarketShopper_Final_Light.3gp",
            "IBB_Ibibio_Nigeria_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-10 09:40:37"
    },
    "1299": {
        "id": "1299",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Ibibio",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/ogG5ZYsPIa8",
        "filename": "IBB_Ibibio_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "IBB_Ibibio_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "IBB_Ibibio_Nigeria_CoronavirusMaskUsage_Final.mov",
            "IBB_Ibibio_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "IBB_Ibibio_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "IBB_Ibibio_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-07 12:08:14"
    },
    "1361": {
        "id": "1361",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Ibibio",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/ZjJPX7YPZdQ",
        "filename": "IBB_Ibibio_Nigeria_PICSVar1_Final",
        "files": [
            "IBB_Ibibio_Nigeria_PICSVar1_Final.3gp",
            "IBB_Ibibio_Nigeria_PICSVar1_Final.mov",
            "IBB_Ibibio_Nigeria_PICSVar1_Final.mp4",
            "IBB_Ibibio_Nigeria_PICSVar1_Final_Light.3gp",
            "IBB_Ibibio_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-29 17:20:44"
    },
    "1433": {
        "id": "1433",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Idoma",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/M6PVO_l6zhM",
        "filename": "IDO_Idoma_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "IDO_Idoma_Nigeria_CoronavirusPublicTransportation_Final",
            "IDO_Idoma_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "IDO_Idoma_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "IDO_Idoma_Nigeria_CoronavirusPublicTransportation_Final_Light",
            "IDO_Idoma_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-04 17:01:04"
    },
    "1434": {
        "id": "1434",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Idoma",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/pQIOVqdyYWY",
        "filename": "IDO_Idoma_Nigeria_PICSVar1_Final",
        "files": [
            "IDO_Idoma_Nigeria_PICSVar1_Final.3gp",
            "IDO_Idoma_Nigeria_PICSVar1_Final.mov",
            "IDO_Idoma_Nigeria_PICSVar1_Final.mp4",
            "IDO_Idoma_Nigeria_PICSVar1_Final_Light.3gp",
            "IDO_Idoma_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-04 17:13:40"
    },
    "1478": {
        "id": "1478",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Idoma",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/v0rmlVzuDMM",
        "filename": " IDO_Idoma_Nigeria_CoronavirusMaskUsage_Final ",
        "files": [],
        "publishDate": "2021-12-02 09:10:14"
    },
    "1045": {
        "id": "1045",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Igbo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/0SM7bcDv8O4",
        "filename": "IG_Igbo_Nigeria_PHLJerrycanFTF_Final",
        "files": [
            "IG_Igbo_Nigeria_PHLJerrycanFTF_Final.3gp",
            "IG_Igbo_Nigeria_PHLJerrycanFTF_Final.mov",
            "IG_Igbo_Nigeria_PHLJerrycanFTF_Final.mp4",
            "IG_Igbo_Nigeria_PHLJerrycanFTF_Final_Light.3gp",
            "IG_Igbo_Nigeria_PHLJerrycanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-03 12:41:03"
    },
    "1177": {
        "id": "1177",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Igbo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/pVlfM4WMAh8",
        "filename": "IG_Igbo_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "IG_Igbo_Nigeria_CoronavirusMarketplace_Final.3gp",
            "IG_Igbo_Nigeria_CoronavirusMarketplace_Final.mov",
            "IG_Igbo_Nigeria_CoronavirusMarketplace_Final.mp4",
            "IG_Igbo_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "IG_Igbo_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-05 09:03:02"
    },
    "1274": {
        "id": "1274",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Igbo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/pi29EL5pBIs",
        "filename": "IG_Igbo_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "IG_Igbo_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "IG_Igbo_Nigeria_CoronavirusMaskUsage_Final.mov",
            "IG_Igbo_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "IG_Igbo_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "IG_Igbo_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-11 10:35:13"
    },
    "1046": {
        "id": "1046",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Ijaw",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/fsQFxv4ZXCY",
        "filename": "IJO_Ijaw_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "IJO_Ijaw_Nigeria_PHLJerryCanFTF_Final.3gp",
            "IJO_Ijaw_Nigeria_PHLJerryCanFTF_Final.mov",
            "IJO_Ijaw_Nigeria_PHLJerryCanFTF_Final.mp4",
            "IJO_Ijaw_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "IJO_Ijaw_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-03 12:51:45"
    },
    "1205": {
        "id": "1205",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Ijaw",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/wIAKbxLNW-8",
        "filename": "IJO_Ijaw_Nigeria_CoronavirusMarketManager_Final",
        "files": [
            "IJO_Ijaw_Nigeria_CoronavirusMarketManager_Final.3gp",
            "IJO_Ijaw_Nigeria_CoronavirusMarketManager_Final.mov",
            "IJO_Ijaw_Nigeria_CoronavirusMarketManager_Final.mp4",
            "IJO_Ijaw_Nigeria_CoronavirusMarketManager_Final_Light.3gp",
            "IJO_Ijaw_Nigeria_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-30 08:26:02"
    },
    "1214": {
        "id": "1214",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Ijaw",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/cj96DabHFyc",
        "filename": "IJO_Ijaw_Nigeria_CoronavirusMarketShopper_Final",
        "files": [
            "IJO_Ijaw_Nigeria_CoronavirusMarketShopper_Final.3gp",
            "IJO_Ijaw_Nigeria_CoronavirusMarketShopper_Final.mov",
            "IJO_Ijaw_Nigeria_CoronavirusMarketShopper_Final.mp4",
            "IJO_Ijaw_Nigeria_CoronavirusMarketShopper_Final_Light.3gp",
            "IJO_Ijaw_Nigeria_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-10 09:18:09"
    },
    "1275": {
        "id": "1275",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Ijaw",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/aVIYB8PaQyU",
        "filename": "IJ_Ijaw_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "IJ_Ijaw_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "IJ_Ijaw_Nigeria_CoronavirusMaskUsage_Final.mov",
            "IJ_Ijaw_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "IJ_Ijaw_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "IJ_Ijaw_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-11 10:47:43"
    },
    "1362": {
        "id": "1362",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Ijaw",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/OPHdqhMS4Zo",
        "filename": "IJ_Ijaw_Nigeria_PICSVar1_Final",
        "files": [
            "IJ_Ijaw_Nigeria_PICSVar1_Final.3gp",
            "IJ_Ijaw_Nigeria_PICSVar1_Final.mov",
            "IJ_Ijaw_Nigeria_PICSVar1_Final.mp4",
            "IJ_Ijaw_Nigeria_PICSVar1_Final_Light.3gp",
            "IJ_Ijaw_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-29 17:37:32"
    },
    "1444": {
        "id": "1444",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Isoko",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/J7mQWybIwcY",
        "filename": "ISO_Isoko_Nigeria_PHLJerrycan_FTF",
        "files": [
            "ISO_Isoko_Nigeria_PHLJerrycan_FTF_Final.3gp",
            "ISO_Isoko_Nigeria_PHLJerrycan_FTF_Final.mov",
            "ISO_Isoko_Nigeria_PHLJerrycan_FTF_Final.mp4",
            "ISO_Isoko_Nigeria_PHLJerrycan_FTF_Final_Light.3gp",
            "ISO_Isoko_Nigeria_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-11 09:53:53"
    },
    "1471": {
        "id": "1471",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Isoko",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/QgwF9fUfTU4",
        "filename": "ISO_Isoko_Nigeria_CoronavirusNewcastleDiseaseVar1_Final",
        "files": [
            "ISO_Isoko_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.3gp",
            "ISO_Isoko_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.mov",
            "ISO_Isoko_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.mp4",
            "ISO_Isoko_Nigeria_CoronavirusNewcastleDiseaseVar1_Final_Light.3gp",
            "ISO_Isoko_Nigeria_CoronavirusNewcastleDiseaseVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-01 08:34:10"
    },
    "1432": {
        "id": "1432",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Itshekiri",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/SZeWu-CVOO8",
        "filename": "ITS_Itshekiri_Nigeria_PHLJerrycan_FTF_Final",
        "files": [
            "ITS_Itshekiri_Nigeria_PHLJerrycan_FTF_Final.3gp",
            "ITS_Itshekiri_Nigeria_PHLJerrycan_FTF_Final.mov",
            "ITS_Itshekiri_Nigeria_PHLJerrycan_FTF_Final.mp4",
            "ITS_Itshekiri_Nigeria_PHLJerrycan_FTF_Final_Light.3gp",
            "ITS_Itshekiri_Nigeria_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-04 16:33:30"
    },
    "1443": {
        "id": "1443",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Kalabari",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/26rCY2Q6HCk",
        "filename": "KAL-Kalabari_Nigeria_PHLJerrycan_FTF_Final",
        "files": [
            "KAL-Kalabari_Nigeria_PHLJerrycan_FTF_Final.3gp",
            "KAL-Kalabari_Nigeria_PHLJerrycan_FTF_Final.mov",
            "KAL-Kalabari_Nigeria_PHLJerrycan_FTF_Final.mp4",
            "KAL-Kalabari_Nigeria_PHLJerrycan_FTF_Final_Light.3gp",
            "KAL-Kalabari_Nigeria_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-11 09:30:26"
    },
    "1077": {
        "id": "1077",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Kamba",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/Q09KzXixGtg",
        "filename": "KAM_Kamba_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "KAM_Kamba_Kenya_PHLJerryCanFTF_Final.3gp",
            "KAM_Kamba_Kenya_PHLJerryCanFTF_Final.mov",
            "KAM_Kamba_Kenya_PHLJerryCanFTF_Final.mp4",
            "KAM_Kamba_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "KAM_Kamba_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-12 13:59:29"
    },
    "1209": {
        "id": "1209",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Kamba",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/1TPiemTResY",
        "filename": "KAM_Kamba_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "KAM_Kamba_Kenya_CoronavirusMarketManager_Final.3gp",
            "KAM_Kamba_Kenya_CoronavirusMarketManager_Final.mov",
            "KAM_Kamba_Kenya_CoronavirusMarketManager_Final.mp4",
            "KAM_Kamba_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "KAM_Kamba_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-06 12:17:24"
    },
    "1221": {
        "id": "1221",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Kamba",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/q1Ps8QGnlH4",
        "filename": "KAM_Kamba_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "KAM_Kamba_Kenya_CoronavirusMarketShopper_Final.3gp",
            "KAM_Kamba_Kenya_CoronavirusMarketShopper_Final.mov",
            "KAM_Kamba_Kenya_CoronavirusMarketShopper_Final.mp4",
            "KAM_Kamba_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "KAM_Kamba_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-10 13:03:54"
    },
    "1374": {
        "id": "1374",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Kamba",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/NG1wA4EyS78",
        "filename": "KAM_Kamba_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "KAM_Kamba_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "KAM_Kamba_Kenya_CoronavirusPublicTransportation_Final.mov",
            "KAM_Kamba_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "KAM_Kamba_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "KAM_Kamba_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-10 18:52:43"
    },
    "1457": {
        "id": "1457",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Kamba",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks ",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/cs2_LtzjpVY",
        "filename": "KAM_Kamba_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "KAM_Kamba_Kenya_CoronavirusMaskUsage_Final.3gp",
            "KAM_Kamba_Kenya_CoronavirusMaskUsage_Final.mov",
            "KAM_Kamba_Kenya_CoronavirusMaskUsage_Final.mp4",
            "KAM_Kamba_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "KAM_Kamba_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-22 09:00:28"
    },
    "1052": {
        "id": "1052",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Kanuri",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/TAJweIMooyo",
        "filename": "KR1_Kanuri_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "KR1_Kanuri_Nigeria_PHLJerryCanFTF_Final.3gp",
            "KR1_Kanuri_Nigeria_PHLJerryCanFTF_Final.mov",
            "KR1_Kanuri_Nigeria_PHLJerryCanFTF_Final.mp4",
            "KR1_Kanuri_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "KR1_Kanuri_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-04 09:36:00"
    },
    "1183": {
        "id": "1183",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Kanuri",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/Mnom7UBDO7A",
        "filename": "KR1_Kanuri_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "KR1_Kanuri_Nigeria_CoronavirusMarketplace_Final.3gp",
            "KR1_Kanuri_Nigeria_CoronavirusMarketplace_Final.mov",
            "KR1_Kanuri_Nigeria_CoronavirusMarketplace_Final.mp4",
            "KR1_Kanuri_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "KR1_Kanuri_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-19 08:32:20"
    },
    "1217": {
        "id": "1217",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Kanuri",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/zDLvuFouYIU",
        "filename": "KR_Kanuri_Nigeria_CoronavirusMarketShopper_Final",
        "files": [
            "KR_Kanuri_Nigeria_CoronavirusMarketShopper_Final.3gp",
            "KR_Kanuri_Nigeria_CoronavirusMarketShopper_Final.mov",
            "KR_Kanuri_Nigeria_CoronavirusMarketShopper_Final.mp4",
            "KR_Kanuri_Nigeria_CoronavirusMarketShopper_Final_Light.3gp",
            "KR_Kanuri_Nigeria_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-10 11:45:49"
    },
    "1363": {
        "id": "1363",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Kanuri",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/CXUiPTJstt8",
        "filename": "KNR_Kanuri_Nigeria_PICSVar1_Final",
        "files": [
            "KNR_Kanuri_Nigeria_PICSVar1_Final.3gp",
            "KNR_Kanuri_Nigeria_PICSVar1_Final.mov",
            "KNR_Kanuri_Nigeria_PICSVar1_Final.mp4",
            "KNR_Kanuri_Nigeria_PICSVar1_Final_Light.3gp",
            "KNR_Kanuri_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-30 05:54:39"
    },
    "1365": {
        "id": "1365",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Kanuri",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/M0gO-pABiDk",
        "filename": "KR1_Kanuri_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "KR1_Kanuri_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "KR1_Kanuri_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "KR1_Kanuri_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "KR1_Kanuri_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "KR1_Kanuri_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-01 09:13:59"
    },
    "1109": {
        "id": "1109",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Karekare",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/ubIaBUlx0Bs",
        "filename": "KAI_Karekare_Nigeria_PHLJerrycanFTF_Final",
        "files": [
            "KAI_Karekare_Nigeria_PHLJerrycanFTF_Final.3gp",
            "KAI_Karekare_Nigeria_PHLJerrycanFTF_Final.mov",
            "KAI_Karekare_Nigeria_PHLJerrycanFTF_Final.mp4",
            "KAI_Karekare_Nigeria_PHLJerrycanFTF_Final_Light.3gp",
            "KAI_Karekare_Nigeria_PHLJerrycanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-28 09:31:10"
    },
    "1400": {
        "id": "1400",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Kasem",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/KBXv0yvrKfQ",
        "filename": "XSM_Kasem_Ghana_CoronavirusMarketShopper_Final",
        "files": [
            "XSM_Kasem_Ghana_CoronavirusMarketShopper_Final.3gp",
            "XSM_Kasem_Ghana_CoronavirusMarketShopper_Final.mov",
            "XSM_Kasem_Ghana_CoronavirusMarketShopper_Final.mp4",
            "XSM_Kasem_Ghana_CoronavirusMarketShopper_Final_Light.3gp",
            "XSM_Kasem_Ghana_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-18 10:29:49"
    },
    "1167": {
        "id": "1167",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Kasem",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/fd12sk3JzAU",
        "filename": "XSM_Kasem_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "XSM_Kasem_Ghana_CoronavirusMarketplace_Final.3gp",
            "XSM_Kasem_Ghana_CoronavirusMarketplace_Final.mov",
            "XSM_Kasem_Ghana_CoronavirusMarketplace_Final.mp4",
            "XSM_Kasem_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "XSM_Kasem_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-26 13:15:58"
    },
    "1213": {
        "id": "1213",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Kasem",
        "country": "Ghana",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/WPvNrSTwCX8",
        "filename": "XSM_Kasem_Ghana_CoronavirusMarketManager_Final",
        "files": [
            "XSM_Kasem_Ghana_CoronavirusMarketManager_Final.3gp",
            "XSM_Kasem_Ghana_CoronavirusMarketManager_Final.mov",
            "XSM_Kasem_Ghana_CoronavirusMarketManager_Final.mp4",
            "XSM_Kasem_Ghana_CoronavirusMarketManager_Final_Light.3gp",
            "XSM_Kasem_Ghana_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-06 12:53:07"
    },
    "1322": {
        "id": "1322",
        "topic": "CoronavirusMaskUsageVar2",
        "category": "Health",
        "language": "Kasem",
        "country": "Ghana",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "custom": null,
        "url": "//www.youtube.com/embed/JLxwUYWCCoM",
        "filename": "XSM_Kasem_Ghana_CoronavirusMaskUsageVar2_Final",
        "files": [
            "XSM_Kasem_Ghana_CoronavirusMaskUsageVar2_Final.3gp",
            "XSM_Kasem_Ghana_CoronavirusMaskUsageVar2_Final.mov",
            "XSM_Kasem_Ghana_CoronavirusMaskUsageVar2_Final.mp4",
            "XSM_Kasem_Ghana_CoronavirusMaskUsageVar2_Final_Light.3gp",
            "XSM_Kasem_Ghana_CoronavirusMaskUsageVar2_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-17 09:12:52"
    },
    "1332": {
        "id": "1332",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Kasem",
        "country": "Ghana",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/9564HQO7c74",
        "filename": "KSM_Kasem_Ghana_CoronavirusPublicTransportation_Final",
        "files": [
            "KSM_Kasem_Ghana_CoronavirusPublicTransportation_Final.3gp",
            "KSM_Kasem_Ghana_CoronavirusPublicTransportation_Final.mov",
            "KSM_Kasem_Ghana_CoronavirusPublicTransportation_Final.mp4",
            "KSM_Kasem_Ghana_CoronavirusPublicTransportation_Final_Light.3gp",
            "KSM_Kasem_Ghana_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 09:55:25"
    },
    "987": {
        "id": "987",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Kasem",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/GhOvwma2-K0",
        "filename": "XSM_Kasem_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "XSM_Kasem_Ghana_PHLJerryCanFTF_Final.3gp",
            "XSM_Kasem_Ghana_PHLJerryCanFTF_Final.mov",
            "XSM_Kasem_Ghana_PHLJerryCanFTF_Final.mp4",
            "XSM_Kasem_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "XSM_Kasem_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-09-01 18:33:19"
    },
    "1080": {
        "id": "1080",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Kikuyu",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/BuxYZFpk5oY",
        "filename": "KIK_Kikuyu_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "KIK_Kikuyu_Kenya_PHLJerryCanFTF_Final.3gp",
            "KIK_Kikuyu_Kenya_PHLJerryCanFTF_Final.mov",
            "KIK_Kikuyu_Kenya_PHLJerryCanFTF_Final.mp4",
            "KIK_Kikuyu_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "KIK_Kikuyu_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-13 08:27:37"
    },
    "1118": {
        "id": "1118",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Kipsigis",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/6lsbITj7Nto",
        "filename": "SGC_Kipsigis_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "SGC_Kipsigis_Kenya_PHLJerryCanFTF_Final.3gp",
            "SGC_Kipsigis_Kenya_PHLJerryCanFTF_Final.mov",
            "SGC_Kipsigis_Kenya_PHLJerryCanFTF_Final.mp4",
            "SGC_Kipsigis_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "SGC_Kipsigis_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-08 10:51:58"
    },
    "1336": {
        "id": "1336",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Kipsigis",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/4w7bq8LyU70",
        "filename": "SGC_Kipsigis_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "SGC_Kipsigis_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "SGC_Kipsigis_Kenya_CoronavirusPublicTransportation_Final.mov",
            "SGC_Kipsigis_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "SGC_Kipsigis_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "SGC_Kipsigis_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 11:35:59"
    },
    "1352": {
        "id": "1352",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Kipsigis",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace ",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/Nu-qpNqqYv8",
        "filename": "SGC_Kipsigis_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "SGC_Kipsigis_Kenya_CoronavirusMarketShopper_Final.3gp",
            "SGC_Kipsigis_Kenya_CoronavirusMarketShopper_Final.mov",
            "SGC_Kipsigis_Kenya_CoronavirusMarketShopper_Final.mp4",
            "SGC_Kipsigis_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "SGC_Kipsigis_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-29 09:24:49"
    },
    "1383": {
        "id": "1383",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Kipsigis",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/XyY3wi-FYOM",
        "filename": "SGC_Kipsigis_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "SGC_Kipsigis_Kenya_CoronavirusMarketManager_Final.3gp",
            "SGC_Kipsigis_Kenya_CoronavirusMarketManager_Final.mov",
            "SGC_Kipsigis_Kenya_CoronavirusMarketManager_Final.mp4",
            "SGC_Kipsigis_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "SGC_Kipsigis_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-12 21:14:55"
    },
    "1460": {
        "id": "1460",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Kipsigis",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/ocuLHfoDAqw",
        "filename": "SGC_ Kipsigis_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "SGC_ Kipsigis_Kenya_CoronavirusMaskUsage_Final.3gp",
            "SGC_ Kipsigis_Kenya_CoronavirusMaskUsage_Final.mov",
            "SGC_ Kipsigis_Kenya_CoronavirusMaskUsage_Final.mp4",
            "SGC_ Kipsigis_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "SGC_ Kipsigis_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-22 10:02:45"
    },
    "1480": {
        "id": "1480",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Kisii",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/jRYoCnieP64",
        "filename": "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final.mov",
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-02 09:42:57"
    },
    "1481": {
        "id": "1481",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Kisii",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/jRYoCnieP64",
        "filename": "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final.mov",
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "KIS_Kisii_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-02 11:18:43"
    },
    "1172": {
        "id": "1172",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Konkomba",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/w7BKobUYHm8",
        "filename": "KON_Konkomba_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "KON_Konkomba_Ghana_CoronavirusMarketplace_Final.3gp",
            "KON_Konkomba_Ghana_CoronavirusMarketplace_Final.mov",
            "KON_Konkomba_Ghana_CoronavirusMarketplace_Final.mp4",
            "KON_Konkomba_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "KON_Konkomba_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-31 11:55:18"
    },
    "1163": {
        "id": "1163",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Kusaal",
        "country": "Ghana",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/jLRtMRrnaDc",
        "filename": "KUS_Kusaal_Ghana_CoronavirusMarketplace_Final",
        "files": [
            "KUS_Kusaal_Ghana_CoronavirusMarketplace_Final.3gp",
            "KUS_Kusaal_Ghana_CoronavirusMarketplace_Final.mov",
            "KUS_Kusaal_Ghana_CoronavirusMarketplace_Final.mp4",
            "KUS_Kusaal_Ghana_CoronavirusMarketplace_Final_Light.3gp",
            "KUS_Kusaal_Ghana_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-25 09:58:09"
    },
    "966": {
        "id": "966",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Kusaal",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/qoUUkYPLaAQ",
        "filename": "KUS_Kusaal_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "KUS_Kusaal_Ghana_PHLJerryCanFTF_Final.3gp",
            "KUS_Kusaal_Ghana_PHLJerryCanFTF_Final.mov",
            "KUS_Kusaal_Ghana_PHLJerryCanFTF_Final.mp4",
            "KUS_Kusaal_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "KUS_Kusaal_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-31 20:28:14"
    },
    "1125": {
        "id": "1125",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Idakho",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/hyuCv9FPf0U",
        "filename": "IDA_Luhya-Idakho_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "IDA_Luhya-Idakho_Kenya_PHLJerryCanFTF_Final.3gp",
            "IDA_Luhya-Idakho_Kenya_PHLJerryCanFTF_Final.mov",
            "IDA_Luhya-Idakho_Kenya_PHLJerryCanFTF_Final.mp4",
            "IDA_Luhya-Idakho_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "IDA_Luhya-Idakho_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-22 11:46:12"
    },
    "1133": {
        "id": "1133",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Isukha",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/kd9A9v2wkWU",
        "filename": "ISU_Luhya-Isukha_Kenya_PHLJerrycan_Final",
        "files": [
            "ISU_Luhya-Isukha_Kenya_PHLJerrycan_Final.3gp",
            "ISU_Luhya-Isukha_Kenya_PHLJerrycan_Final.mov",
            "ISU_Luhya-Isukha_Kenya_PHLJerrycan_Final.mp4",
            "ISU_Luhya-Isukha_Kenya_PHLJerrycan_Final_Light.3gp",
            "ISU_Luhya-Isukha_Kenya_PHLJerrycan_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-23 16:27:11"
    },
    "1127": {
        "id": "1127",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Kabras",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/aiMdKFk2vS4",
        "filename": "LKB_Luhya-Kabras_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "LKB_Luhya-Kabras_Kenya_PHLJerryCanFTF_Final.3gp",
            "LKB_Luhya-Kabras_Kenya_PHLJerryCanFTF_Final.mov",
            "LKB_Luhya-Kabras_Kenya_PHLJerryCanFTF_Final.mp4",
            "LKB_Luhya-Kabras_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "LKB_Luhya-Kabras_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-22 15:39:15"
    },
    "1112": {
        "id": "1112",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Khayo",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/KaYGIQze69Y",
        "filename": "LKO_Luhya-Khayo_Kenya_PHLJerrycanFTF_Final",
        "files": [
            "LKO_Luhya-Khayo_Kenya_PHLJerrycanFTF_Final.3gp",
            "LKO_Luhya-Khayo_Kenya_PHLJerrycanFTF_Final.mov",
            "LKO_Luhya-Khayo_Kenya_PHLJerrycanFTF_Final.mp4",
            "LKO_Luhya-Khayo_Kenya_PHLJerrycanFTF_Final_Light.3gp",
            "LKO_Luhya-Khayo_Kenya_PHLJerrycanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-02 13:04:20"
    },
    "1132": {
        "id": "1132",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Kisa",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/EFRyfVTN__M",
        "filename": "KIS_Luhya-Kisa_Kenya_PHLJerrycanFTF_Final",
        "files": [
            "KIS_Luhya-Kisa_Kenya_PHLJerrycanFTF_Final.3gp",
            "KIS_Luhya-Kisa_Kenya_PHLJerrycanFTF_Final.mov",
            "KIS_Luhya-Kisa_Kenya_PHLJerrycanFTF_Final.mp4",
            "KIS_Luhya-Kisa_Kenya_PHLJerrycanFTF_Final_Light.3gp",
            "KIS_Luhya-Kisa_Kenya_PHLJerrycanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-23 16:19:15"
    },
    "1126": {
        "id": "1126",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Marachi",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/jk-3VTkGKiE",
        "filename": "IRI_Luhya-Marachi_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "IRI_Luhya-Marachi_Kenya_PHLJerryCanFTF_Final.3gp",
            "IRI_Luhya-Marachi_Kenya_PHLJerryCanFTF_Final.mov",
            "IRI_Luhya-Marachi_Kenya_PHLJerryCanFTF_Final.mp4",
            "IRI_Luhya-Marachi_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "IRI_Luhya-Marachi_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-22 14:07:32"
    },
    "1122": {
        "id": "1122",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Maragoli",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/ietzo444zdM",
        "filename": "MAR_Luhya-Maragoli_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "MAR_Luhya-Maragoli_Kenya_PHLJerryCanFTF_Final.3gp",
            "MAR_Luhya-Maragoli_Kenya_PHLJerryCanFTF_Final.mov",
            "MAR_Luhya-Maragoli_Kenya_PHLJerryCanFTF_Final.mp4",
            "MAR_Luhya-Maragoli_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "MAR_Luhya-Maragoli_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-11 08:40:43"
    },
    "1128": {
        "id": "1128",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Marama",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/mxhA3rGQaos",
        "filename": "LRM_Luhya-Marama_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "LRM_Luhya-Marama_Kenya_PHLJerryCanFTF_Final.3gp",
            "LRM_Luhya-Marama_Kenya_PHLJerryCanFTF_Final.mov",
            "LRM_Luhya-Marama_Kenya_PHLJerryCanFTF_Final.mp4",
            "LRM_Luhya-Marama_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "LRM_Luhya-Marama_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-23 06:28:26"
    },
    "1136": {
        "id": "1136",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Samia",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/x84ud8TK2yc",
        "filename": "LSM_Luhya-Samia_PHLJerryCanFTF_Final",
        "files": [
            "LSM_Luhya-Samia_PHLJerryCanFTF_Final.3gp",
            "LSM_Luhya-Samia_PHLJerryCanFTF_Final.mov",
            "LSM_Luhya-Samia_PHLJerryCanFTF_Final.mp4",
            "LSM_Luhya-Samia_PHLJerryCanFTF_Final_Light.3gp",
            "LSM_Luhya-Samia_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-25 08:50:59"
    },
    "1129": {
        "id": "1129",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Tsotso",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/_xiRrEOfxYw",
        "filename": "LT_Luhya-Tsotso_PHLJerryCanFTF_Final",
        "files": [
            "LT_Luhya-Tsotso_PHLJerryCanFTF_Final.3gp",
            "LT_Luhya-Tsotso_PHLJerryCanFTF_Final.mov",
            "LT_Luhya-Tsotso_PHLJerryCanFTF_Final.mp4",
            "LT_Luhya-Tsotso_PHLJerryCanFTF_Final_Light.3gp",
            "LT_Luhya-Tsotso_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-23 06:56:46"
    },
    "1130": {
        "id": "1130",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luhya-Wanga",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/XFq0sWwWaA4",
        "filename": "LWG_Luhya-Wanga_PHLJerryCanFTF_Final",
        "files": [
            "LWG_Luhya-Wanga_PHLJerryCanFTF_Final.3gp",
            "LWG_Luhya-Wanga_PHLJerryCanFTF_Final.mov",
            "LWG_Luhya-Wanga_PHLJerryCanFTF_Final.mp4",
            "LWG_Luhya-Wanga_PHLJerryCanFTF_Final_Light.3gp",
            "LWG_Luhya-Wanga_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-23 08:24:42"
    },
    "1078": {
        "id": "1078",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Luo",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/1ArrjQzM9uc",
        "filename": "LUO_Luo_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "LUO_Luo_Kenya_PHLJerryCanFTF_Final.3gp",
            "LUO_Luo_Kenya_PHLJerryCanFTF_Final.mov",
            "LUO_Luo_Kenya_PHLJerryCanFTF_Final.mp4",
            "LUO_Luo_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "LUO_Luo_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-12 14:04:07"
    },
    "1277": {
        "id": "1277",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Luo",
        "country": "Kenya",
        "subtitles": null,
        "title": "properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/m_eZcc1U-10",
        "filename": "LUO_LUO_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "LUO_LUO_Kenya_CoronavirusMaskUsage_Final.3gp",
            "LUO_LUO_Kenya_CoronavirusMaskUsage_Final.mov",
            "LUO_LUO_Kenya_CoronavirusMaskUsage_Final.mp4",
            "LUO_LUO_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "LUO_LUO_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-15 18:53:36"
    },
    "1318": {
        "id": "1318",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Luo",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/EJKgVcPa84I",
        "filename": "LUO_Luo_Kenya_PICSVar1_Final",
        "files": [
            "LUO_Luo_Kenya_PICSVar1_Final.3gp",
            "LUO_Luo_Kenya_PICSVar1_Final.mov",
            "LUO_Luo_Kenya_PICSVar1_Final.mp4",
            "LUO_Luo_Kenya_PICSVar1_Final_Light.3gp",
            "LUO_Luo_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-16 18:34:42"
    },
    "1380": {
        "id": "1380",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Luo",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/uuMgOzuKsKc",
        "filename": "LUO_Luo_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "LUO_Luo_Kenya_CoronavirusMarketShopper_Final.3gp",
            "LUO_Luo_Kenya_CoronavirusMarketShopper_Final.mov",
            "LUO_Luo_Kenya_CoronavirusMarketShopper_Final.mp4",
            "LUO_Luo_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "LUO_Luo_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-12 20:29:39"
    },
    "1392": {
        "id": "1392",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Luo",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/jDn0EpCPJYM",
        "filename": "LUO_Luo_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "LUO_Luo_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "LUO_Luo_Kenya_CoronavirusPublicTransportation_Final.mov",
            "LUO_Luo_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "LUO_Luo_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "LUO_Luo_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-15 12:08:38"
    },
    "1081": {
        "id": "1081",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Maasai",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/_bLj3Nf6wg0",
        "filename": "MAS_Maasai_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "MAS_Maasai_Kenya_PHLJerryCanFTF_Final.3gp",
            "MAS_Maasai_Kenya_PHLJerryCanFTF_Final.mov",
            "MAS_Maasai_Kenya_PHLJerryCanFTF_Final.mp4",
            "MAS_Maasai_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "MAS_Maasai_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-13 09:25:51"
    },
    "1334": {
        "id": "1334",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Maasai",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/vyYm5nYFqGM",
        "filename": "MS_Masai-Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "MS_Masai-Kenya_CoronavirusPublicTransportation_Final.3gp",
            "MS_Masai-Kenya_CoronavirusPublicTransportation_Final.mov",
            "MS_Masai-Kenya_CoronavirusPublicTransportation_Final.mp4",
            "MS_Masai-Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "MS_Masai-Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 10:17:11"
    },
    "1348": {
        "id": "1348",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Maasai",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader ",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/R-uEi0NF7EA",
        "filename": "MAS_Maasai_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "MAS_Maasai_Kenya_CoronavirusMarketManager_Final.3gp",
            "MAS_Maasai_Kenya_CoronavirusMarketManager_Final.mov",
            "MAS_Maasai_Kenya_CoronavirusMarketManager_Final.mp4",
            "MAS_Maasai_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "MAS_Maasai_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-28 18:38:09"
    },
    "1407": {
        "id": "1407",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Maasai",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/fkl_DabUX3I",
        "filename": "MAS_Maasai_Kenya_PICSVar1_Final",
        "files": [
            "MAS_Maasai_Kenya_PICSVar1_Final.3gp",
            "MAS_Maasai_Kenya_PICSVar1_Final.mov",
            "MAS_Maasai_Kenya_PICSVar1_Final.mp4",
            "MAS_Maasai_Kenya_PICSVar1_Final_Light.3gp",
            "MAS_Maasai_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-22 10:02:00"
    },
    "1098": {
        "id": "1098",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Marghi",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/kRgOcqdDt-k",
        "filename": "MFM_Marghi_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "MFM_Marghi_Nigeria_PHLJerryCanFTF_Final.3gp",
            "MFM_Marghi_Nigeria_PHLJerryCanFTF_Final.mov",
            "MFM_Marghi_Nigeria_PHLJerryCanFTF_Final.mp4",
            "MFM_Marghi_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "MFM_Marghi_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-21 08:56:29"
    },
    "1387": {
        "id": "1387",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Marghi",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/7RA5TANyr7k",
        "filename": "MFM_Marghi_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "MFM_Marghi_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "MFM_Marghi_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "MFM_Marghi_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "MFM_Marghi_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "MFM_Marghi_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-14 13:42:13"
    },
    "1388": {
        "id": "1388",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Marghi",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks ",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/nvCDpeygPz4",
        "filename": "MFM_Marghi_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "MFM_Marghi_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "MFM_Marghi_Nigeria_CoronavirusMaskUsage_Final.mov",
            "MFM_Marghi_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "MFM_Marghi_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "MFM_Marghi_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-14 13:59:59"
    },
    "1437": {
        "id": "1437",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Marghi",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/0f2MnHm3vvY",
        "filename": "MFM_Marghi_Nigeria_PICSVar1_Final",
        "files": [
            "MFM_Marghi_Nigeria_PICSVar1_Final.3gp",
            "MFM_Marghi_Nigeria_PICSVar1_Final.mov",
            "MFM_Marghi_Nigeria_PICSVar1_Final.mp4",
            "MFM_Marghi_Nigeria_PICSVar1_Final_Light.3gp",
            "MFM_Marghi_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-05 10:40:04"
    },
    "1082": {
        "id": "1082",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Meru",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/E2N99tjuJPs",
        "filename": "MER_Meru_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "MER_Meru_Kenya_PHLJerryCanFTF_Final.3gp",
            "MER_Meru_Kenya_PHLJerryCanFTF_Final.mov",
            "MER_Meru_Kenya_PHLJerryCanFTF_Final.mp4",
            "MER_Meru_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "MER_Meru_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-13 09:33:33"
    },
    "1294": {
        "id": "1294",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Meru",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/A9M9C_-3ZvU",
        "filename": "MER_Meru_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "MER_Meru_Kenya_CoronavirusMaskUsage_Final.3gp",
            "MER_Meru_Kenya_CoronavirusMaskUsage_Final.mov",
            "MER_Meru_Kenya_CoronavirusMaskUsage_Final.mp4",
            "MER_Meru_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "MER_Meru_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-02 14:14:06"
    },
    "1347": {
        "id": "1347",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Meru",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/i-uhi6o6osk",
        "filename": "MER_Meru_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "MER_Meru_Kenya_CoronavirusMarketManager_Final.3gp",
            "MER_Meru_Kenya_CoronavirusMarketManager_Final.mov",
            "MER_Meru_Kenya_CoronavirusMarketManager_Final.mp4",
            "MER_Meru_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "MER_Meru_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-28 18:23:55"
    },
    "1351": {
        "id": "1351",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Meru",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/eoxV2jjvfEY",
        "filename": "MER_Meru_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "MER_Meru_Kenya_CoronavirusMarketShopper_Final .mov",
            "MER_Meru_Kenya_CoronavirusMarketShopper_Final.3gp",
            "MER_Meru_Kenya_CoronavirusMarketShopper_Final.mp4",
            "MER_Meru_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "MER_Meru_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-28 19:21:01"
    },
    "1393": {
        "id": "1393",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Meru",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/jxuz_bGyWW4",
        "filename": "MER_Meru_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "MER_Meru_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "MER_Meru_Kenya_CoronavirusPublicTransportation_Final.mov",
            "MER_Meru_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "MER_Meru_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "MER_Meru_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-15 13:35:14"
    },
    "1409": {
        "id": "1409",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Meru",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/IevkqxNhY_U",
        "filename": "MER_Meru_Kenya_PICSVar1_Final",
        "files": [
            "MER_Meru_Kenya_PICSVar1_Final.3gp",
            "MER_Meru_Kenya_PICSVar1_Final.mov",
            "MER_Meru_Kenya_PICSVar1_Final.mp4",
            "MER_Meru_Kenya_PICSVar1_Final_Light.3gp",
            "MER_Meru_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-22 10:44:11"
    },
    "1227": {
        "id": "1227",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Mijikenda-Jibana",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/Y9TiL0X5Frc",
        "filename": "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMarketShopper_Final.3gp",
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMarketShopper_Final.mov",
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMarketShopper_Final.mp4",
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-19 11:29:55"
    },
    "1307": {
        "id": "1307",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Mijikenda-Jibana",
        "country": "Kenya",
        "subtitles": null,
        "title": "properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/57cCpXzR_xw",
        "filename": "MIJ2_Mijikenda_Jibana_Kenya_PICSVar1_Final",
        "files": [
            "MIJ2_Mijikenda_Jibana_Kenya_PICSVar1_Final.3gp",
            "MIJ2_Mijikenda_Jibana_Kenya_PICSVar1_Final.mov",
            "MIJ2_Mijikenda_Jibana_Kenya_PICSVar1_Final.mp4",
            "MIJ2_Mijikenda_Jibana_Kenya_PICSVar1_Final_Light.3gp",
            "MIJ2_Mijikenda_Jibana_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-08 12:21:27"
    },
    "1339": {
        "id": "1339",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Mijikenda-Jibana",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/fgkuGWLcRo4",
        "filename": "MJJ2_Mijikenda_Jibana_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "MJJ2_Mijikenda_Jibana_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "MJJ2_Mijikenda_Jibana_Kenya_CoronavirusPublicTransportation_Final.mov",
            "MJJ2_Mijikenda_Jibana_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "MJJ2_Mijikenda_Jibana_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "MJJ2_Mijikenda_Jibana_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 12:35:53"
    },
    "1418": {
        "id": "1418",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Mijikenda-Jibana",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/s1ygISGnx60",
        "filename": "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMaskUsage_Final.3gp",
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMaskUsage_Final.mov",
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMaskUsage_Final.mp4",
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "MIJ2_Mijikenda-Jibana_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-02 11:34:02"
    },
    "1488": {
        "id": "1488",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Mijikenda-Kambe",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/f72Pqz_txgo",
        "filename": "MIJ1_Mijikenda-Kambe_Kenya_PHLJerryCanFTF_Final ",
        "files": [],
        "publishDate": "2021-12-03 09:59:55"
    },
    "1138": {
        "id": "1138",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Mijikenda-Kauma",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/wY4wOyiUgIs",
        "filename": "MIJ_Mijikenda-Kauma_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "MIJ_Mijikenda-Kauma_Kenya_PHLJerryCanFTF_Final.3gp",
            "MIJ_Mijikenda-Kauma_Kenya_PHLJerryCanFTF_Final.mov",
            "MIJ_Mijikenda-Kauma_Kenya_PHLJerryCanFTF_Final.mp4",
            "MIJ_Mijikenda-Kauma_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "MIJ_Mijikenda-Kauma_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-02 10:47:04"
    },
    "1414": {
        "id": "1414",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Mijikenda-Rabai",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/z8BnTE3Zz48",
        "filename": "MIJ4_Mijikenda_Rabai_Kenya_PICSVar1_Final",
        "files": [
            "MIJ4_Mijikenda_Rabai_Kenya_PICSVar1_Final.3gp",
            "MIJ4_Mijikenda_Rabai_Kenya_PICSVar1_Final.mov",
            "MIJ4_Mijikenda_Rabai_Kenya_PICSVar1_Final.mp4",
            "MIJ4_Mijikenda_Rabai_Kenya_PICSVar1_Final_Light.3gp",
            "MIJ4_Mijikenda_Rabai_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-28 15:35:17"
    },
    "1468": {
        "id": "1468",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Mijikenda-Rabai",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/EeWLl6sqLus",
        "filename": "RAB_Rabai_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "RAB_Rabai_Kenya_CoronavirusMaskUsage_Final.3gp",
            "RAB_Rabai_Kenya_CoronavirusMaskUsage_Final.mov",
            "RAB_Rabai_Kenya_CoronavirusMaskUsage_Final.mp4",
            "RAB_Rabai_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "RAB_Rabai_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-30 12:33:52"
    },
    "1479": {
        "id": "1479",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Mijikenda-Rabai",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/ItHWjNQ8DQE",
        "filename": "MIJ4_Mijikenda_Rabai_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "MIJ4_Mijikenda_Rabai_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "MIJ4_Mijikenda_Rabai_Kenya_CoronavirusPublicTransportation_Final.mov",
            "MIJ4_Mijikenda_Rabai_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "MIJ4_Mijikenda_Rabai_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "MIJ4_Mijikenda_Rabai_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-02 09:28:51"
    },
    "1320": {
        "id": "1320",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Mijikenda-Ribe",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/IoYQX3EGLZo",
        "filename": "MIJ3_Mijikenda_Ribe_Kenya_PICSVar1_Final",
        "files": [
            "MIJ3_Mijikenda_Ribe_Kenya_PICSVar1_Final.3gp",
            "MIJ3_Mijikenda_Ribe_Kenya_PICSVar1_Final.mov",
            "MIJ3_Mijikenda_Ribe_Kenya_PICSVar1_Final.mp4",
            "MIJ3_Mijikenda_Ribe_Kenya_PICSVar1_Final_Light.3gp",
            "MIJ3_Mijikenda_Ribe_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-16 19:31:56"
    },
    "1399": {
        "id": "1399",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Mijikenda-Ribe",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/CEwBmXwtNhY",
        "filename": "MIJ3_ Mijikenda-Ribe_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "MIJ3_ Mijikenda-Ribe_Kenya_CoronavirusMarketShopper_Final.3gp",
            "MIJ3_ Mijikenda-Ribe_Kenya_CoronavirusMarketShopper_Final.mov",
            "MIJ3_ Mijikenda-Ribe_Kenya_CoronavirusMarketShopper_Final.mp4",
            "MIJ3_ Mijikenda-Ribe_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "MIJ3_ Mijikenda-Ribe_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-18 10:15:52"
    },
    "1083": {
        "id": "1083",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Nandi",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/-uG7fYsx-lI",
        "filename": "NIQ_Nandi_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "NIQ_Nandi_Kenya_PHLJerryCanFTF_Final.3gp",
            "NIQ_Nandi_Kenya_PHLJerryCanFTF_Final.mov",
            "NIQ_Nandi_Kenya_PHLJerryCanFTF_Final.mp4",
            "NIQ_Nandi_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "NIQ_Nandi_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-13 09:47:05"
    },
    "1210": {
        "id": "1210",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Nandi",
        "country": "Kenya",
        "subtitles": null,
        "title": " Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/jChg0--fPD4",
        "filename": "KLN_Nandi_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "KLN_Nandi_Kenya_CoronavirusMarketManager_Final.3gp",
            "KLN_Nandi_Kenya_CoronavirusMarketManager_Final.mov",
            "KLN_Nandi_Kenya_CoronavirusMarketManager_Final.mp4",
            "KLN_Nandi_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "KLN_Nandi_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-06 12:32:16"
    },
    "1216": {
        "id": "1216",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Nandi",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/5VSi4ROrRvc",
        "filename": "KLN_Nandi_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "KLN_Nandi_Kenya_CoronavirusMarketShopper_Final.3gp",
            "KLN_Nandi_Kenya_CoronavirusMarketShopper_Final.mov",
            "KLN_Nandi_Kenya_CoronavirusMarketShopper_Final.mp4",
            "KLN_Nandi_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "KLN_Nandi_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-10 10:26:08"
    },
    "1278": {
        "id": "1278",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Nandi",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/PGCAViQ0c00",
        "filename": "KLN_Nandi_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "KLN_Nandi_Kenya_CoronavirusMaskUsage_Final.3gp",
            "KLN_Nandi_Kenya_CoronavirusMaskUsage_Final.mov",
            "KLN_Nandi_Kenya_CoronavirusMaskUsage_Final.mp4",
            "KLN_Nandi_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "KLN_Nandi_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-18 08:44:55"
    },
    "1305": {
        "id": "1305",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Nandi",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/R-tHLdlFwxI",
        "filename": "KLN_Nandi_Kenya_PICSVar1_Final",
        "files": [
            "KLN_Nandi_Kenya_PICSVar1_Final.3gp",
            "KLN_Nandi_Kenya_PICSVar1_Final.mov",
            "KLN_Nandi_Kenya_PICSVar1_Final.mp4",
            "KLN_Nandi_Kenya_PICSVar1_Final_Light.3gp",
            "KLN_Nandi_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-08 06:01:11"
    },
    "1331": {
        "id": "1331",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Nandi",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/FAaezXu9v2c",
        "filename": "KLN_Nandi_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "KLN_Nandi_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "KLN_Nandi_Kenya_CoronavirusPublicTransportation_Final.mov",
            "KLN_Nandi_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "KLN_Nandi_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "KLN_Nandi_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 09:47:25"
    },
    "1100": {
        "id": "1100",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Nupe",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/GwTEKNIQtTY",
        "filename": "NUP_Nupe_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "NUP_Nupe_Nigeria_PHLJerryCanFTF_Final.3gp",
            "NUP_Nupe_Nigeria_PHLJerryCanFTF_Final.mov",
            "NUP_Nupe_Nigeria_PHLJerryCanFTF_Final.mp4",
            "NUP_Nupe_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "NUP_Nupe_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-21 10:50:57"
    },
    "965": {
        "id": "965",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Nzema",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/W8bLBvzAIlk",
        "filename": "NZI_Nzema_Ghana_PHLJerryCanFTF_Final",
        "files": [
            "NZI_Nzema_Ghana_PHLJerryCanFTF_Final.3gp",
            "NZI_Nzema_Ghana_PHLJerryCanFTF_Final.mov",
            "NZI_Nzema_Ghana_PHLJerryCanFTF_Final.mp4",
            "NZI_Nzema_Ghana_PHLJerryCanFTF_Final_Light.3gp",
            "NZI_Nzema_Ghana_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-08-27 13:57:29"
    },
    "1345": {
        "id": "1345",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Oron",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/oajWxj_w4OI",
        "filename": "ORO_Oron_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "ORO_Oron_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "ORO_Oron_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "ORO_Oron_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "ORO_Oron_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "ORO_Oron_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-27 11:14:57"
    },
    "1110": {
        "id": "1110",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Pidgin",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/6ioxTwfd0DY",
        "filename": "PCM_Pidgin_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "PCM_Pidgin_Nigeria_PHLJerryCanFTF_Final.3gp",
            "PCM_Pidgin_Nigeria_PHLJerryCanFTF_Final.mov",
            "PCM_Pidgin_Nigeria_PHLJerryCanFTF_Final.mp4",
            "PCM_Pidgin_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "PCM_Pidgin_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-01-28 09:48:19"
    },
    "1366": {
        "id": "1366",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Pidgin",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/2rlNwrb5jow",
        "filename": "PDG_Pidgin_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "PDG_Pidgin_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "PDG_Pidgin_Nigeria_CoronavirusMaskUsage_Final.mov",
            "PDG_Pidgin_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "PDG_Pidgin_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "PDG_Pidgin_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-05 10:46:13"
    },
    "1485": {
        "id": "1485",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Pokomo",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/usXVy3nkH78",
        "filename": " PKB-Pokomo_Kenya_PHLJerrycanFTF_Final",
        "files": [],
        "publishDate": "2021-12-02 14:01:23"
    },
    "1120": {
        "id": "1120",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Pokot",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/mcBokEt1_CY",
        "filename": "PKO_Pokot_Kenya_PHLJerrycanFTF_Final",
        "files": [
            "PKO_Pokot_Kenya_PHLJerrycanFTF_Final.3gp",
            "PKO_Pokot_Kenya_PHLJerrycanFTF_Final.mov",
            "PKO_Pokot_Kenya_PHLJerrycanFTF_Final.mp4",
            "PKO_Pokot_Kenya_PHLJerrycanFTF_Final_Light.3gp",
            "PKO_Pokot_Kenya_PHLJerrycanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-08 12:35:13"
    },
    "1226": {
        "id": "1226",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Pokot",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/D8XrbM-eYhY",
        "filename": "PKO_Pokot_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "PKO_Pokot_Kenya_CoronavirusMarketShopper_Final.3gp",
            "PKO_Pokot_Kenya_CoronavirusMarketShopper_Final.mov",
            "PKO_Pokot_Kenya_CoronavirusMarketShopper_Final.mp4",
            "PKO_Pokot_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "PKO_Pokot_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-19 10:57:39"
    },
    "1235": {
        "id": "1235",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Pokot",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/7rq4fRs8d1g",
        "filename": "PKO_Pokot_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "PKO_Pokot_Kenya_CoronavirusMarketManager_Final.3gp",
            "PKO_Pokot_Kenya_CoronavirusMarketManager_Final.mov",
            "PKO_Pokot_Kenya_CoronavirusMarketManager_Final.mp4",
            "PKO_Pokot_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "PKO_Pokot_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-25 06:37:27"
    },
    "1335": {
        "id": "1335",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Pokot",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/7xTV8umj-AY",
        "filename": "PKO_Pokot_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "PKO_Pokot_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "PKO_Pokot_Kenya_CoronavirusPublicTransportation_Final.mov",
            "PKO_Pokot_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "PKO_Pokot_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "PKO_Pokot_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-23 10:56:20"
    },
    "1368": {
        "id": "1368",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Pokot",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/lfq9UI7P998",
        "filename": "PKO_Pokot_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "PKO_Pokot_Kenya_CoronavirusMaskUsage_Final.3gp",
            "PKO_Pokot_Kenya_CoronavirusMaskUsage_Final.mov",
            "PKO_Pokot_Kenya_CoronavirusMaskUsage_Final.mp4",
            "PKO_Pokot_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "PKO_Pokot_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-06 09:48:44"
    },
    "1507": {
        "id": "1507",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Pokot",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/DRfKsPZyIwU",
        "filename": " PKO_Pokot_Kenya_PICSVar1_Final",
        "files": [],
        "publishDate": "2021-12-09 11:49:18"
    },
    "1293": {
        "id": "1293",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Ribe",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/CZ59Vh7LsVw",
        "filename": "RIB_Ribe_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "RIB_Ribe_Kenya_CoronavirusMaskUsage_Final.3gp",
            "RIB_Ribe_Kenya_CoronavirusMaskUsage_Final.mov",
            "RIB_Ribe_Kenya_CoronavirusMaskUsage_Final.mp4",
            "RIB_Ribe_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "RIB_Ribe_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-02 13:45:42"
    },
    "1117": {
        "id": "1117",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Sabaot",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/pd8yy_Xl4uU",
        "filename": "SPY_Sabaot_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "SPY_Sabaot_Kenya_PHLJerryCanFTF_Final.3gp",
            "SPY_Sabaot_Kenya_PHLJerryCanFTF_Final.mov",
            "SPY_Sabaot_Kenya_PHLJerryCanFTF_Final.mp4",
            "SPY_Sabaot_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "SPY_Sabaot_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-08 10:34:14"
    },
    "1319": {
        "id": "1319",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Sabaot",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/4jYW55Z-btI",
        "filename": "SPY_Sabaot_Kenya_PICSVar1_Final",
        "files": [
            "SPY_Sabaot_Kenya_PICSVar1_Final.3gp",
            "SPY_Sabaot_Kenya_PICSVar1_Final.mov",
            "SPY_Sabaot_Kenya_PICSVar1_Final.mp4",
            "SPY_Sabaot_Kenya_PICSVar1_Final_Light.3gp",
            "SPY_Sabaot_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-16 19:13:25"
    },
    "1353": {
        "id": "1353",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Sabaot",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/ZYKTQZzuZwY",
        "filename": "SPY_Sabaot_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "SPY_Sabaot_Kenya_CoronavirusMarketManager_Final.3gp",
            "SPY_Sabaot_Kenya_CoronavirusMarketManager_Final.mov",
            "SPY_Sabaot_Kenya_CoronavirusMarketManager_Final.mp4",
            "SPY_Sabaot_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "SPY_Sabaot_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-29 09:34:57"
    },
    "1381": {
        "id": "1381",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Sabaot",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace ",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/LhfNdKj5gyo",
        "filename": "SPY_Sabaot_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "SPY_Sabaot_Kenya_CoronavirusMarketShopper_Final.3gp",
            "SPY_Sabaot_Kenya_CoronavirusMarketShopper_Final.mov",
            "SPY_Sabaot_Kenya_CoronavirusMarketShopper_Final.mp4",
            "SPY_Sabaot_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "SPY_Sabaot_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-12 20:43:37"
    },
    "1419": {
        "id": "1419",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Sabaot",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/-M_1wOIMuxg",
        "filename": "SPY_Sabaot_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "SPY_Sabaot_Kenya_CoronavirusMaskUsage_Final.3gp",
            "SPY_Sabaot_Kenya_CoronavirusMaskUsage_Final.mov",
            "SPY_Sabaot_Kenya_CoronavirusMaskUsage_Final.mp4",
            "SPY_Sabaot_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "SPY_Sabaot_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-02 11:48:24"
    },
    "1484": {
        "id": "1484",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Samburu",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/M_Ir0PKP91E",
        "filename": "SAQ_Samburu_Kenya_PHLJerrycan_FTF_Final",
        "files": [
            "SAQ_Samburu_Kenya_PHLJerrycan_FTF_Final.3gp",
            "SAQ_Samburu_Kenya_PHLJerrycan_FTF_Final.mov",
            "SAQ_Samburu_Kenya_PHLJerrycan_FTF_Final.mp4",
            "SAQ_Samburu_Kenya_PHLJerrycan_FTF_Final_Light.3gp",
            "SAQ_Samburu_Kenya_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-02 13:36:32"
    },
    "1447": {
        "id": "1447",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Sefwi",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/cLojSvxcxRA",
        "filename": "SA_Sefwi_Ghana_PHLJerrycan_FTF_Final",
        "files": [
            "SA_Sefwi_Ghana_PHLJerrycan_FTF_Final.3gp",
            "SA_Sefwi_Ghana_PHLJerrycan_FTF_Final.mov",
            "SA_Sefwi_Ghana_PHLJerrycan_FTF_Final.mp4",
            "SA_Sefwi_Ghana_PHLJerrycan_FTF_Final_Light.3gp",
            "SA_Sefwi_Ghana_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-16 09:27:12"
    },
    "1373": {
        "id": "1373",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Shuwa Arab",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/4LYLvhhtiA8",
        "filename": "SHU_Shuwa Arab_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "SHU_Shuwa Arab_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "SHU_Shuwa Arab_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "SHU_Shuwa Arab_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "SHU_Shuwa Arab_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "SHU_Shuwa Arab_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-10 18:31:59"
    },
    "1420": {
        "id": "1420",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Shuwa Arab",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/9tDguG1Fv_s",
        "filename": "SHU_Shuwa Arab_Nigeria_PICSVar1_Final",
        "files": [
            "SHU_Shuwa Arab_Nigeria_PICSVar1_Final.3gp",
            "SHU_Shuwa Arab_Nigeria_PICSVar1_Final.mov",
            "SHU_Shuwa Arab_Nigeria_PICSVar1_Final.mp4",
            "SHU_Shuwa Arab_Nigeria_PICSVar1_Final_Light.3gp",
            "SHU_Shuwa Arab_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-02 13:30:36"
    },
    "1438": {
        "id": "1438",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Sisaali",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/nSaV93Tsr0Q",
        "filename": "SIS_Sisaali_Ghana_PHLJerrycan_FTF_Final",
        "files": [
            "SIS_Sisaali_Ghana_PHLJerrycan_FTF_Final.3gp",
            "SIS_Sisaali_Ghana_PHLJerrycan_FTF_Final.mov",
            "SIS_Sisaali_Ghana_PHLJerrycan_FTF_Final.mp4",
            "SIS_Sisaali_Ghana_PHLJerrycan_FTF_Final_Light.3gp",
            "SIS_Sisaali_Ghana_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-09 07:55:58"
    },
    "1135": {
        "id": "1135",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Somali",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/ApKIDITvGso",
        "filename": "SOM1_Somali_Kenya_PHLJerrycanFTF_Final",
        "files": [
            "SOM1_Somali_Kenya_PHLJerrycanFTF_Final.3gp",
            "SOM1_Somali_Kenya_PHLJerrycanFTF_Final.mov",
            "SOM1_Somali_Kenya_PHLJerrycanFTF_Final.mp4",
            "SOM1_Somali_Kenya_PHLJerrycanFTF_Final_Light.3gp",
            "SOM1_Somali_Kenya_PHLJerrycanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-23 16:51:55"
    },
    "1141": {
        "id": "1141",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Spanish",
        "country": "Venezuela",
        "subtitles": null,
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/nzaVcFCbfXs",
        "filename": "ES8_Spanish_Venezuela_CoronavirusMarketplace_Final",
        "files": [
            "ES8_Spanish_Venezuela_CoronavirusMarketplace_Final.3gp",
            "ES8_Spanish_Venezuela_CoronavirusMarketplace_Final.mov",
            "ES8_Spanish_Venezuela_CoronavirusMarketplace_Final.mp4",
            "ES8_Spanish_Venezuela_CoronavirusMarketplace_Final_Light.3gp",
            "ES8_Spanish_Venezuela_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-08 14:13:27"
    },
    "1228": {
        "id": "1228",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Spanish",
        "country": "Venezuela",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/UVzZz0wOQWg",
        "filename": "SP_Spanish_Venezuela_Marketplace Manager_Final",
        "files": [
            "SP_Spanish_Venezuela_Marketplace Manager_Final.3gp",
            "SP_Spanish_Venezuela_Marketplace Manager_Final.mov",
            "SP_Spanish_Venezuela_Marketplace Manager_Final.mp4",
            "SP_Spanish_Venezuela_Marketplace Manager_Final_Light.3gp",
            "SP_Spanish_Venezuela_Marketplace Manager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-20 08:00:39"
    },
    "1346": {
        "id": "1346",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Spanish",
        "country": "Venezuela",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/QgpB4JhfsiE",
        "filename": "ES8_Spanish_Venezuela_CoronavirusTransportation_Final",
        "files": [
            "ES8_Spanish_Venezuela_CoronavirusTransportation_Final.3gp",
            "ES8_Spanish_Venezuela_CoronavirusTransportation_Final.mov",
            "ES8_Spanish_Venezuela_CoronavirusTransportation_Final.mp4",
            "ES8_Spanish_Venezuela_CoronavirusTransportation_Final_Light.3gp",
            "ES8_Spanish_Venezuela_CoronavirusTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-28 07:45:05"
    },
    "1375": {
        "id": "1375",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Spanish",
        "country": "Venezuela",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/IQNWrhS2HTk",
        "filename": "ES8_Spanish_Venezuela_PICSVar1_Final",
        "files": [
            "ES8_Spanish_Venezuela_PICSVar1_Final.3gp",
            "ES8_Spanish_Venezuela_PICSVar1_Final.mov",
            "ES8_Spanish_Venezuela_PICSVar1_Final.mp4",
            "ES8_Spanish_Venezuela_PICSVar1_Final_Light.3gp",
            "ES8_Spanish_Venezuela_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-10 19:26:19"
    },
    "1459": {
        "id": "1459",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Spanish",
        "country": "Venezuela",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/H97daqb1Y-M",
        "filename": "ES8_Spanish_Venezuela_CoronavirusMaskUsage_Final",
        "files": [
            "ES8_Spanish_Venezuela_CoronavirusMaskUsage_Final.3gp",
            "ES8_Spanish_Venezuela_CoronavirusMaskUsage_Final.mov",
            "ES8_Spanish_Venezuela_CoronavirusMaskUsage_Final.mp4",
            "ES8_Spanish_Venezuela_CoronavirusMaskUsage_Final_Light.3gp",
            "ES8_Spanish_Venezuela_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-22 09:36:44"
    },
    "1009": {
        "id": "1009",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Swahili",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/B2cnDwW-eb8",
        "filename": "SW_Swahili_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "SW_Swahili_Kenya_PHLJerryCanFTF_Final.3gp",
            "SW_Swahili_Kenya_PHLJerryCanFTF_Final.mov",
            "SW_Swahili_Kenya_PHLJerryCanFTF_Final.mp4",
            "SW_Swahili_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "SW_Swahili_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-10-16 10:32:43"
    },
    "1149": {
        "id": "1149",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Swahili",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/QCnwieYUtnY",
        "filename": "SW_Swahili_Kenya_CoronavirusMarketplace_Final",
        "files": [
            "SW_Swahili_Kenya_CoronavirusMarketplace_Final.3gp",
            "SW_Swahili_Kenya_CoronavirusMarketplace_Final.mov",
            "SW_Swahili_Kenya_CoronavirusMarketplace_Final.mp4",
            "SW_Swahili_Kenya_CoronavirusMarketplace_Final_Light.3gp",
            "SW_Swahili_Kenya_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-16 10:32:43"
    },
    "1212": {
        "id": "1212",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Swahili",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "This video is a short explanation of how SAWBO improves people's lives through spreading knowledge.  SAWBO is drawing a better world.",
        "custom": null,
        "url": "//www.youtube.com/embed/pEd3WDyYxDw",
        "filename": "SW_Swahili_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "SW_Swahili_Kenya_CoronavirusMarketManager_Final.3gp",
            "SW_Swahili_Kenya_CoronavirusMarketManager_Final.mov",
            "SW_Swahili_Kenya_CoronavirusMarketManager_Final.mp4",
            "SW_Swahili_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "SW_Swahili_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-06 12:47:07"
    },
    "1291": {
        "id": "1291",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Swahili",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace ",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/at4aqDlBZbI",
        "filename": "SW_Swahili_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "SW_Swahili_Kenya_CoronavirusMarketShopper_Final.3gp",
            "SW_Swahili_Kenya_CoronavirusMarketShopper_Final.mov",
            "SW_Swahili_Kenya_CoronavirusMarketShopper_Final.mp4",
            "SW_Swahili_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "SW_Swahili_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-31 13:10:29"
    },
    "1306": {
        "id": "1306",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Swahili",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/wg6bKqJU-BI",
        "filename": "SW_Swahili_Kenya_PICSVar1_Final",
        "files": [
            "SW_Swahili_Kenya_PICSVar1_Final.3gp",
            "SW_Swahili_Kenya_PICSVar1_Final.mov",
            "SW_Swahili_Kenya_PICSVar1_Final.mp4",
            "SW_Swahili_Kenya_PICSVar1_Final_Light.3gp",
            "SW_Swahili_Kenya_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-08 10:54:48"
    },
    "1456": {
        "id": "1456",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Swahili",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/D5d7mWm4WDA",
        "filename": "SW_Swahili_Kenya_CoronavirusMaskUsage_Final",
        "files": [
            "SW_Swahili_Kenya_CoronavirusMaskUsage_Final.3gp",
            "SW_Swahili_Kenya_CoronavirusMaskUsage_Final.mov",
            "SW_Swahili_Kenya_CoronavirusMaskUsage_Final.mp4",
            "SW_Swahili_Kenya_CoronavirusMaskUsage_Final_Light.3gp",
            "SW_Swahili_Kenya_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-22 08:39:40"
    },
    "1486": {
        "id": "1486",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Swahili",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/QWQE5lNa1hk",
        "filename": "SW_Swahili_Kenya_CoronavirusNewcastleDisease_Final",
        "files": [
            "SW_Swahili_Kenya_CoronavirusNewcastleDisease_Final.3gp",
            "SW_Swahili_Kenya_CoronavirusNewcastleDisease_Final.mov",
            "SW_Swahili_Kenya_CoronavirusNewcastleDisease_Final.mp4",
            "SW_Swahili_Kenya_CoronavirusNewcastleDisease_Final_Light.3gp",
            "SW_Swahili_Kenya_CoronavirusNewcastleDisease_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-03 09:21:27"
    },
    "1121": {
        "id": "1121",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Tangale",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/mPFcpHMqxlY",
        "filename": "TAN_Tangale_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "TAN_Tangale_Nigeria_PHLJerryCanFTF_Final.3gp",
            "TAN_Tangale_Nigeria_PHLJerryCanFTF_Final.mov",
            "TAN_Tangale_Nigeria_PHLJerryCanFTF_Final.mp4",
            "TAN_Tangale_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "TAN_Tangale_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-02-11 08:21:15"
    },
    "1140": {
        "id": "1140",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Taveta-Taita",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/DUA4RhjmxhU",
        "filename": "TVS_Taveta-Taita_Kenya_PHLJerryCanFTF_Final",
        "files": [
            "TVS_Taveta-Taita_Kenya_PHLJerryCanFTF_Final.3gp",
            "TVS_Taveta-Taita_Kenya_PHLJerryCanFTF_Final.mov",
            "TVS_Taveta-Taita_Kenya_PHLJerryCanFTF_Final.mp4",
            "TVS_Taveta-Taita_Kenya_PHLJerryCanFTF_Final_Light.3gp",
            "TVS_Taveta-Taita_Kenya_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-02 11:59:52"
    },
    "1349": {
        "id": "1349",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Taveta-Taita",
        "country": "Kenya",
        "subtitles": null,
        "title": "Marketplace-Market Leader ",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/6Ju3jW0N_oE",
        "filename": "TVS_Taveta Taita_Kenya_CoronavirusMarketManager_Final",
        "files": [
            "TVS_Taveta Taita_Kenya_CoronavirusMarketManager_Final.3gp",
            "TVS_Taveta Taita_Kenya_CoronavirusMarketManager_Final.mov",
            "TVS_Taveta Taita_Kenya_CoronavirusMarketManager_Final.mp4",
            "TVS_Taveta Taita_Kenya_CoronavirusMarketManager_Final_Light.3gp",
            "TVS_Taveta Taita_Kenya_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-28 18:53:43"
    },
    "1378": {
        "id": "1378",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Taveta-Taita",
        "country": "Kenya",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/dP3UmA5el3Y",
        "filename": "TVS_Taveta_Taita_Kenya_CoronavirusMarketShopper_Final",
        "files": [
            "TVS_Taveta_Taita_Kenya_CoronavirusMarketShopper_Final.3gp",
            "TVS_Taveta_Taita_Kenya_CoronavirusMarketShopper_Final.mov",
            "TVS_Taveta_Taita_Kenya_CoronavirusMarketShopper_Final.mp4",
            "TVS_Taveta_Taita_Kenya_CoronavirusMarketShopper_Final_Light.3gp",
            "TVS_Taveta_Taita_Kenya_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-12 20:06:54"
    },
    "1395": {
        "id": "1395",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Taveta-Taita",
        "country": "Kenya",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic ",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/C8X4wuxRsI0",
        "filename": "TVS_Taveta Taita_Kenya_CoronavirusPublicTransportation_Final",
        "files": [
            "TVS_Taveta Taita_Kenya_CoronavirusPublicTransportation_Final.3gp",
            "TVS_Taveta Taita_Kenya_CoronavirusPublicTransportation_Final.mov",
            "TVS_Taveta Taita_Kenya_CoronavirusPublicTransportation_Final.mp4",
            "TVS_Taveta Taita_Kenya_CoronavirusPublicTransportation_Final_Light.3gp",
            "TVS_Taveta Taita_Kenya_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-18 09:22:56"
    },
    "1410": {
        "id": "1410",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Taveta-Taita",
        "country": "Kenya",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags ",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/eVW8xLNXphI",
        "filename": "TVS_Taveta-Taita_PICSVar1_Final",
        "files": [
            "TVS_Taveta-Taita_PICSVar1_Final.3gp",
            "TVS_Taveta-Taita_PICSVar1_Final.mov",
            "TVS_Taveta-Taita_PICSVar1_Final.mp4",
            "TVS_Taveta-Taita_PICSVar1_Final_Light.3gp",
            "TVS_Taveta-Taita_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-10-22 10:52:02"
    },
    "1451": {
        "id": "1451",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Tigania",
        "country": "Kenya",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage ",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/tA8_Ee7ihA0",
        "filename": "MER1_Tigania-Kenya_PHLJerrycan_FTF_Final",
        "files": [
            "MER1_Tigania-Kenya_PHLJerrycan_FTF_Final.3gp",
            "MER1_Tigania-Kenya_PHLJerrycan_FTF_Final.mov",
            "MER1_Tigania-Kenya_PHLJerrycan_FTF_Final.mp4",
            "MER1_Tigania-Kenya_PHLJerrycan_FTF_Final_Light.3gp",
            "MER1_Tigania-Kenya_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-17 12:27:43"
    },
    "1049": {
        "id": "1049",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Tiv",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/_0YztcBL_nQ",
        "filename": "TIV_Tiv_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "TIV_Tiv_Nigeria_PHLJerryCanFTF_Final.3gp",
            "TIV_Tiv_Nigeria_PHLJerryCanFTF_Final.mov",
            "TIV_Tiv_Nigeria_PHLJerryCanFTF_Final.mp4",
            "TIV_Tiv_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "TIV_Tiv_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-04 08:34:20"
    },
    "1182": {
        "id": "1182",
        "topic": "CoronavirusMarketplace",
        "category": "Health",
        "language": "Tiv",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID-19 Pandemic: How to Sell Safely in The Marketplace",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/hiVqGzg7o6E",
        "filename": "TIV_Tiv_Nigeria_CoronavirusMarketplace_Final",
        "files": [
            "TIV_Tiv_Nigeria_CoronavirusMarketplace_Final.3gp",
            "TIV_Tiv_Nigeria_CoronavirusMarketplace_Final.mov",
            "TIV_Tiv_Nigeria_CoronavirusMarketplace_Final.mp4",
            "TIV_Tiv_Nigeria_CoronavirusMarketplace_Final_Light.3gp",
            "TIV_Tiv_Nigeria_CoronavirusMarketplace_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-16 09:03:14"
    },
    "1206": {
        "id": "1206",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Tiv",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/oCMq-mo7OcE",
        "filename": "TIV_Tiv_Nigeria_CoronavirusMarketManager_Final",
        "files": [
            "TIV_Tiv_Nigeria_CoronavirusMarketManager_Final.3gp",
            "TIV_Tiv_Nigeria_CoronavirusMarketManager_Final.mov",
            "TIV_Tiv_Nigeria_CoronavirusMarketManager_Final.mp4",
            "TIV_Tiv_Nigeria_CoronavirusMarketManager_Final_Light.3gp",
            "TIV_Tiv_Nigeria_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-04-30 08:37:03"
    },
    "1225": {
        "id": "1225",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Tiv",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/v49TTUHqKg0",
        "filename": "TIV_Tiv_Nigeria_CoronavirusMarketShopper_Final",
        "files": [
            "TIV_Tiv_Nigeria_CoronavirusMarketShopper_Final.3gp",
            "TIV_Tiv_Nigeria_CoronavirusMarketShopper_Final.mov",
            "TIV_Tiv_Nigeria_CoronavirusMarketShopper_Final.mp4",
            "TIV_Tiv_Nigeria_CoronavirusMarketShopper_Final_Light.3gp",
            "TIV_Tiv_Nigeria_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-19 10:10:05"
    },
    "1280": {
        "id": "1280",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Tiv",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/ELVwSF2VVbs",
        "filename": "Tiv_Tiv_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "Tiv_Tiv_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "Tiv_Tiv_Nigeria_CoronavirusMaskUsage_Final.mov",
            "Tiv_Tiv_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "Tiv_Tiv_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "Tiv_Tiv_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-18 09:24:58"
    },
    "1358": {
        "id": "1358",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Tiv",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/5QnRxatWnKc",
        "filename": "Tiv_Tiv_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "Tiv_Tiv_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "Tiv_Tiv_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "Tiv_Tiv_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "Tiv_Tiv_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "Tiv_Tiv_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-29 13:53:02"
    },
    "1364": {
        "id": "1364",
        "topic": "PICSVar1",
        "category": "Agriculture",
        "language": "Tiv",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/IMf3APB3CxE",
        "filename": "Tiv_Tiv_Nigeria_PICSVar1_Final",
        "files": [
            "Tiv_Tiv_Nigeria_PICSVar1_Final.3gp",
            "Tiv_Tiv_Nigeria_PICSVar1_Final.mov",
            "Tiv_Tiv_Nigeria_PICSVar1_Final.mp4",
            "Tiv_Tiv_Nigeria_PICSVar1_Final_Light.3gp",
            "Tiv_Tiv_Nigeria_PICSVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-30 16:27:04"
    },
    "1439": {
        "id": "1439",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Urhobo",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/imOwsIRTPXo",
        "filename": "URH_Urhobo_Nigeria_PHLJerrycan_FTF_Final",
        "files": [
            "URH_Urhobo_Nigeria_PHLJerrycan_FTF_Final.3gp",
            "URH_Urhobo_Nigeria_PHLJerrycan_FTF_Final.mov",
            "URH_Urhobo_Nigeria_PHLJerrycan_FTF_Final.mp4",
            "URH_Urhobo_Nigeria_PHLJerrycan_FTF_Final_Light.3gp",
            "URH_Urhobo_Nigeria_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-09 08:13:01"
    },
    "1431": {
        "id": "1431",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Yakkur",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/Rqqd80VpFDk",
        "filename": "YAK_Yakkur_Nigeria_PHLJerrycan_FTF_Final",
        "files": [
            "YAK_Yakkur_Nigeria_PHLJerrycan_FTF_Final.3gp",
            "YAK_Yakkur_Nigeria_PHLJerrycan_FTF_Final.mov",
            "YAK_Yakkur_Nigeria_PHLJerrycan_FTF_Final.mp4",
            "YAK_Yakkur_Nigeria_PHLJerrycan_FTF_Final_Light.3gp",
            "YAK_Yakkur_Nigeria_PHLJerrycan_FTF_Final_Lite.mp4"
        ],
        "publishDate": "2021-11-04 13:37:37"
    },
    "1472": {
        "id": "1472",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Yakkur",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/CwPjxcODyqw",
        "filename": "YAK-Yakkur_Nigeria_CoronavirusNewcastleDiseaseVar1_Final",
        "files": [
            "YAK-Yakkur_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.3gp",
            "YAK-Yakkur_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.mov",
            "YAK-Yakkur_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.mp4",
            "YAK-Yakkur_Nigeria_CoronavirusNewcastleDiseaseVar1_Final_Light.3gp",
            "YAK-Yakkur_Nigeria_CoronavirusNewcastleDiseaseVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-01 08:42:44"
    },
    "1051": {
        "id": "1051",
        "topic": "PHLJerryCanFTF",
        "category": "Agriculture",
        "language": "Yoruba",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Postharvest Loss: Jerrycan Bean Storage",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/XOf8oPvbO6M",
        "filename": "YO_Yoruba_Nigeria_PHLJerryCanFTF_Final",
        "files": [
            "YO_Yoruba_Nigeria_PHLJerryCanFTF_Final.3gp",
            "YO_Yoruba_Nigeria_PHLJerryCanFTF_Final.mov",
            "YO_Yoruba_Nigeria_PHLJerryCanFTF_Final.mp4",
            "YO_Yoruba_Nigeria_PHLJerryCanFTF_Final_Light.3gp",
            "YO_Yoruba_Nigeria_PHLJerryCanFTF_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-04 08:55:36"
    },
    "1222": {
        "id": "1222",
        "topic": "CoronavirusMarketShopper",
        "category": "Health",
        "language": "Yoruba",
        "country": "Nigeria",
        "subtitles": null,
        "title": "COVID 19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/Lge2e7YWsmQ",
        "filename": "YO_Yoruba_Nigeria_CoronavirusMarketShopper_Final",
        "files": [
            "YO_Yoruba_Nigeria_CoronavirusMarketShopper_Final.3gp",
            "YO_Yoruba_Nigeria_CoronavirusMarketShopper_Final.mov",
            "YO_Yoruba_Nigeria_CoronavirusMarketShopper_Final.mp4",
            "YO_Yoruba_Nigeria_CoronavirusMarketShopper_Final_Light.3gp",
            "YO_Yoruba_Nigeria_CoronavirusMarketShopper_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-11 08:08:28"
    },
    "1231": {
        "id": "1231",
        "topic": "CoronavirusMarketManager",
        "category": "Health",
        "language": "Yoruba",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Marketplace-Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "custom": null,
        "url": "//www.youtube.com/embed/zJ80ZqIw9To",
        "filename": "YO_Yoruba_Nigeria_CoronavirusMarketManager_Final",
        "files": [
            "YO_Yoruba_Nigeria_CoronavirusMarketManager_Final.3gp",
            "YO_Yoruba_Nigeria_CoronavirusMarketManager_Final.mov",
            "YO_Yoruba_Nigeria_CoronavirusMarketManager_Final.mp4",
            "YO_Yoruba_Nigeria_CoronavirusMarketManager_Final_Light.3gp",
            "YO_Yoruba_Nigeria_CoronavirusMarketManager_Final_Lite.mp4"
        ],
        "publishDate": "2021-05-20 09:52:44"
    },
    "1282": {
        "id": "1282",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Yoruba",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": null,
        "url": "//www.youtube.com/embed/NBwMs83ePsM",
        "filename": "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Final",
        "files": [
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Final.3gp",
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Final.mov",
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Final.mp4",
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Final_Light.3gp",
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Final_Lite.mp4"
        ],
        "publishDate": "2021-08-18 10:06:54"
    },
    "1300": {
        "id": "1300",
        "topic": "CoronavirusMaskUsage",
        "category": "Health",
        "language": "Yoruba",
        "country": "Nigeria",
        "subtitles": null,
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "custom": "Female Voice",
        "url": "//www.youtube.com/embed/2dg8M8arR3g",
        "filename": "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Female_Final",
        "files": [
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Female_Final.3gp",
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Female_Final.mov",
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Female_Final.mp4",
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Female_Final_Light.3gp",
            "YO_Yoruba_Nigeria_CoronavirusMaskUsage_Female_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-07 12:26:04"
    },
    "1357": {
        "id": "1357",
        "topic": "CoronavirusPublicTransportation",
        "category": "Health",
        "language": "Yoruba",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "custom": null,
        "url": "//www.youtube.com/embed/aHTYcbOTldo",
        "filename": "YO_Yoruba_Nigeria_CoronavirusPublicTransportation_Final",
        "files": [
            "YO_Yoruba_Nigeria_CoronavirusPublicTransportation_Final.3gp",
            "YO_Yoruba_Nigeria_CoronavirusPublicTransportation_Final.mov",
            "YO_Yoruba_Nigeria_CoronavirusPublicTransportation_Final.mp4",
            "YO_Yoruba_Nigeria_CoronavirusPublicTransportation_Final_Light.3gp",
            "YO_Yoruba_Nigeria_CoronavirusPublicTransportation_Final_Lite.mp4"
        ],
        "publishDate": "2021-09-29 13:42:12"
    },
    "1473": {
        "id": "1473",
        "topic": "CoronavirusNewcastleDiseaseVar1",
        "category": "Health",
        "language": "Yoruba",
        "country": "Nigeria",
        "subtitles": null,
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "custom": null,
        "url": "//www.youtube.com/embed/jWaGGxwUges",
        "filename": "YO_Yoruba_Nigeria_CoronavirusNewcastleDiseaseVar1_Final",
        "files": [
            "YO_Yoruba_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.3gp",
            "YO_Yoruba_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.mov",
            "YO_Yoruba_Nigeria_CoronavirusNewcastleDiseaseVar1_Final.mp4",
            "YO_Yoruba_Nigeria_CoronavirusNewcastleDiseaseVar1_Final_Light.3gp",
            "YO_Yoruba_Nigeria_CoronavirusNewcastleDiseaseVar1_Final_Lite.mp4"
        ],
        "publishDate": "2021-12-01 08:51:29"
    },
    "230": {
        "id": "230",
        "topic": "SGDripIrrigation3D",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Survival Gardening: Drip Irrigation in English (accent from USA)",
        "description": "Drip Irrigation is a technique that allows you to consistently water your entire crop. This technique is especially helpful during a drought or dry season. This technique must be combined with Raised Planting Beds. Please watch our animation on Raised Planting Beds before attempting Drip Irrigation. With drip irrigation, every drop of water can benefit your plants. By not wasting water, you can increase your yields and make your crop production a more efficient process.",
        "custom": null,
        "url": "//www.youtube.com/embed/qXs9Wsna1Ok",
        "filename": "EN_English_USA_SGDripIrrigation3D_Final",
        "files": [
          "EN_English_USA_SGDripIrrigation3D_Final.3gp",
          "EN_English_USA_SGDripIrrigation3D_Final.f4v",
          "EN_English_USA_SGDripIrrigation3D_Final.mov",
          "EN_English_USA_SGDripIrrigation3D_Final.mp4",
          "EN_English_USA_SGDripIrrigation3D_Final_Light.3gp",
          "EN_English_USA_SGDripIrrigation3D_Final_Lite.mp4"
        ],
        "publishDate": "0000-00-00 00:00:00"
      },
      "233": {
        "id": "233",
        "topic": "SGRaisedPlantingBeds3D",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Survival Gardening: Raised Planting Beds in English (accent from USA)",
        "description": "Farming with raised planting beds is a great way to keep your crop safe and increase your yields. This agricultural technique reduces soil compaction and improves the quality of the soil. It protects your plants during times of excess rainfall. This animation describes how to implement this technique and how to maintain your new raised planting beds.",
        "custom": null,
        "url": "//www.youtube.com/embed/V1cNALOpRvw",
        "filename": "EN_English_USA_SGRaisedPlantingBeds3D_Final",
        "files": [
          "EN_English_USA_SGRaisedPlantingBeds3D_Final.3gp",
          "EN_English_USA_SGRaisedPlantingBeds3D_Final.f4v",
          "EN_English_USA_SGRaisedPlantingBeds3D_Final.mov",
          "EN_English_USA_SGRaisedPlantingBeds3D_Final.mp4",
          "EN_English_USA_SGRaisedPlantingBeds3D_Final_Light.3gp",
          "EN_English_USA_SGRaisedPlantingBeds3D_Final_Lite.mp4"
        ],
        "publishDate": "0000-00-00 00:00:00"
      },
      "626": {
        "id": "626",
        "topic": "PHLBeanStorage",
        "category": "Agriculture",
        "language": "English",
        "country": "Ghana",
        "subtitles": null,
        "title": "Postharvest Loss: Hermetic Sealing with Locally Available Containers",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using a locally available, airtight container. Beans can be stored in an airtight container for long periods of time safely. The container being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "custom": null,
        "url": "//www.youtube.com/embed/rCxQk_Tgx90",
        "filename": "EN10_English_Ghana_PHLBeanStorage_Final",
        "files": [
          "EN10_English_Ghana_PHLBeanStorage_Final.3gp",
          "EN10_English_Ghana_PHLBeanStorage_Final.mov",
          "EN10_English_Ghana_PHLBeanStorage_Final.mp4",
          "EN10_English_Ghana_PHLBeanStorage_Final_Light.3gp",
          "EN10_English_Ghana_PHLBeanStorage_Final_Lite.mp4"
        ],
        "publishDate": "2017-03-24 00:00:00"
      },
      "653": {
        "id": "653",
        "topic": "PHLBeanRowPlanting",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Improved Bean Production",
        "description": "Common beans are an important source of food and income for farmers and families. By improving your bean planting and cultivation practices you can have more beans to eat and sell. This animation shows you steps you can take to improve your bean production.",
        "custom": null,
        "url": "//www.youtube.com/embed/5DIKFNml1EU",
        "filename": "EN_English_USA_PHLBeanRowPlanting_Final",
        "files": [
          "EN_English_USA_PHLBeanRowPlanting_Final.3gp",
          "EN_English_USA_PHLBeanRowPlanting_Final.mov",
          "EN_English_USA_PHLBeanRowPlanting_Final.mp4",
          "EN_English_USA_PHLBeanRowPlanting_Final_Light.3gp",
          "EN_English_USA_PHLBeanRowPlanting_Final_Lite.mp4"
        ],
        "publishDate": "2017-10-04 00:00:00"
      },
      "1056": {
        "id": "1056",
        "topic": "GroundnutPractices",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Groundnut: Good Agricultural Practices for Production",
        "description": "Groundnut is a resilient and nutritious crop that can provide you good yield if you follow a few simple actions. This animation explains the best practices for growing groundnut. Follow these recommendations for a good start to the groundnut growing season.",
        "custom": null,
        "url": "//www.youtube.com/embed/xvCA12c9yeM",
        "filename": "EN_English_USA_GroundnutPractices_Final",
        "files": [
          "EN_English_USA_GroundnutPractices_Final.3gp",
          "EN_English_USA_GroundnutPractices_Final.mov",
          "EN_English_USA_GroundnutPractices_Final.mp4",
          "EN_English_USA_GroundnutPractices_Final_Light.3gp",
          "EN_English_USA_GroundnutPractices_Final_Lite.mp4"
        ],
        "publishDate": "2020-12-09 20:55:50"
      },
      "1151": {
        "id": "1151",
        "topic": "GroundnutHarvestStorage",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Groundnut: Good Agricultural Practices from Harvest to Storage",
        "description": "This animation outlines important steps to take at the end of the growing season that can make the most of your groundnut crop. Remember to test for maturity, dry and sort the pods, then store the groundnuts in dry sacks off the ground. These actions will help to make the groundnut crop a success.",
        "custom": null,
        "url": "//www.youtube.com/embed/nImFicCUkG8",
        "filename": "EN_English_USA_GroundnutHarvestStorage_Final",
        "files": [
          "EN_English_USA_GroundnutHarvestStorage_Final.3gp",
          "EN_English_USA_GroundnutHarvestStorage_Final.mov",
          "EN_English_USA_GroundnutHarvestStorage_Final.mp4",
          "EN_English_USA_GroundnutHarvestStorage_Final_Light.3gp",
          "EN_English_USA_GroundnutHarvestStorage_Final_Lite.mp4"
        ],
        "publishDate": "2021-03-17 20:50:39"
      },
      "1244": {
        "id": "1244",
        "topic": "PICS",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "custom": null,
        "url": "//www.youtube.com/embed/1KIO-P1ZsL8",
        "filename": "EN_English_USA_PICS_Final",
        "files": [
          "EN_English_USA_PICS_Final.3gp",
          "EN_English_USA_PICS_Final.mov",
          "EN_English_USA_PICS_Final.mp4",
          "EN_English_USA_PICS_Final_Light.3gp",
          "EN_English_USA_PICS_Final_Lite.mp4"
        ],
        "publishDate": "2021-07-14 21:38:13"
      },
      "1629": {
        "id": "1629",
        "topic": "GroundnutAflatoxin",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Groundnut: Preventing Aflatoxin",
        "description": "Aflatoxin is a harmful substance that can contaminate many crops, including maize and groundnuts. It is important to understand where aflatoxin comes from and how to control it so that we can protect human health and trade.",
        "custom": null,
        "url": "//www.youtube.com/embed/9c1u5rD9GXg",
        "filename": "EN_English_USA_GroundnutAflatoxin_Final",
        "files": [
          "EN_English_USA_GroundnutAflatoxin_Final.3gp",
          "EN_English_USA_GroundnutAflatoxin_Final.mov",
          "EN_English_USA_GroundnutAflatoxin_Final.mp4",
          "EN_English_USA_GroundnutAflatoxin_Final_Light.3gp",
          "EN_English_USA_GroundnutAflatoxin_Final_Lite.mp4"
        ],
        "publishDate": "2022-06-06 11:21:42"
      },
      "1694": {
        "id": "1694",
        "topic": "NeemVar2",
        "category": "Agriculture",
        "language": "English",
        "country": "USA",
        "subtitles": null,
        "title": "Natural Insecticide from Neem Seeds for Niger",
        "description": "Naturally occurring insecticidal compounds can be extracted from neem seeds and sprayed onto crops to prevent pest insect damage. This animation explains how to sort neem fruits, dry them, remove their outer shells, sort the seeds, grind them, mix the powder in water, filter the mixture, and then prepare the solution necessary for spraying on the crops. This version of the animation is for Niger.",
        "custom": null,
        "url": "//www.youtube.com/embed/CUW3QKbsl8U",
        "filename": "EN_English_USA_NeemVar2_Final",
        "files": [
          "EN_English_USA_NeemVar2_Final.3gp",
          "EN_English_USA_NeemVar2_Final.mov",
          "EN_English_USA_NeemVar2_Final.mp4",
          "EN_English_USA_NeemVar2_Final_Light.3gp",
          "EN_English_USA_NeemVar2_Final_Lite.mp4"
        ],
        "publishDate": "2022-12-15 23:05:24"
      }
}

const topics = {
    "PHLJerryCanFTF": {
        "id": "PHLJerryCanFTF",
        "title": "Postharvest Loss: Jerrycan Bean Storage (FTF)",
        "description": "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
        "category": "Agriculture",
        "shortTitle": null,
        "image": "PHLJerryCan.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2020-08-07",
        "sdg": {
            "1": true,
            "2": true,
            "3": false,
            "4": true,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": true,
            "11": false,
            "12": true,
            "13": false,
            "14": false,
            "15": true,
            "16": false,
            "17": false
        },
        "videos": [
            "1445",
            "961",
            "944",
            "955",
            "1430",
            "1075",
            "1449",
            "1099",
            "1450",
            "962",
            "941",
            "945",
            "1165",
            "1043",
            "1134",
            "983",
            "984",
            "1111",
            "1113",
            "980",
            "981",
            "963",
            "947",
            "986",
            "1050",
            "964",
            "1116",
            "985",
            "1115",
            "1048",
            "1045",
            "1046",
            "1444",
            "1432",
            "1443",
            "1077",
            "1052",
            "1109",
            "987",
            "1080",
            "1118",
            "966",
            "1125",
            "1133",
            "1127",
            "1112",
            "1132",
            "1126",
            "1122",
            "1128",
            "1136",
            "1129",
            "1130",
            "1078",
            "1081",
            "1098",
            "1082",
            "1488",
            "1138",
            "1083",
            "1100",
            "965",
            "1110",
            "1485",
            "1120",
            "1117",
            "1484",
            "1447",
            "1438",
            "1135",
            "1009",
            "1121",
            "1140",
            "1451",
            "1049",
            "1439",
            "1431",
            "1051"
        ]
    },
    "CoronavirusMarketplace": {
        "id": "CoronavirusMarketplace",
        "title": "COVID-19: How to Sell Safely in the Marketplace during the Coronavirus Pandemic",
        "description": "The arrival of the Coronavirus changed many parts of community life.  Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusMarketplace.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-02-04",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1164",
            "1137",
            "1150",
            "1173",
            "1170",
            "1169",
            "1168",
            "1197",
            "1174",
            "1114",
            "1157",
            "1166",
            "1124",
            "1142",
            "1200",
            "1171",
            "1175",
            "1178",
            "1176",
            "1177",
            "1183",
            "1167",
            "1172",
            "1163",
            "1141",
            "1149",
            "1182"
        ]
    },
    "CoronavirusMarketShopper": {
        "id": "CoronavirusMarketShopper",
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusMarketShopper.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-03-21",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1218",
            "1248",
            "1290",
            "1379",
            "1158",
            "1237",
            "1219",
            "1240",
            "1401",
            "1220",
            "1229",
            "1398",
            "1215",
            "1214",
            "1221",
            "1217",
            "1400",
            "1352",
            "1380",
            "1351",
            "1227",
            "1399",
            "1216",
            "1226",
            "1381",
            "1291",
            "1378",
            "1225",
            "1222"
        ]
    },
    "CoronavirusMarketShopperVar1": {
        "id": "CoronavirusMarketShopperVar1",
        "title": "COVID-19 Pandemic: How to Shop Safely in the Marketplace Variant for Bangladesh",
        "description": "This animation outlines safe practices to follow when shopping in a marketplace during the COVID-19 pandemic. By following the actions in this video, you can help the market stay open and keep you and your community safe during this pandemic. This is a variant animation for Bangladesh.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusMarketShopperVar1.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-03-21",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1160",
            "1425",
            "1159"
        ]
    },
    "CoronavirusMarketManager": {
        "id": "CoronavirusMarketManager",
        "title": "COVID-19 Pandemic: Marketplace - Market Leader",
        "description": "As a market leader, you have an important role during the Coronavirus pandemic. This animation outlines safe practices to limit Coronavirus exposure in your market. By following the actions in this video, you can help your market stay open and keep you and your community safe during this pandemic.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusMarketManager.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-03-21",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1224",
            "1202",
            "1382",
            "1161",
            "1239",
            "1181",
            "1238",
            "1223",
            "1199",
            "1196",
            "1211",
            "1204",
            "1205",
            "1209",
            "1213",
            "1383",
            "1348",
            "1347",
            "1210",
            "1235",
            "1353",
            "1228",
            "1212",
            "1349",
            "1206",
            "1231"
        ]
    },
    "CoronavirusMaskUsage": {
        "id": "CoronavirusMaskUsage",
        "title": "Properly Using Facemasks",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusMaskUsage.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-06-18",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1385",
            "1413",
            "1279",
            "1405",
            "1242",
            "1281",
            "1455",
            "1458",
            "1272",
            "1367",
            "1273",
            "1299",
            "1478",
            "1274",
            "1275",
            "1457",
            "1460",
            "1277",
            "1388",
            "1294",
            "1418",
            "1468",
            "1278",
            "1366",
            "1368",
            "1293",
            "1419",
            "1459",
            "1456",
            "1280",
            "1282",
            "1300"
        ]
    },
    "PICSVar1": {
        "id": "PICSVar1",
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes.",
        "category": "Agriculture",
        "shortTitle": null,
        "image": "PICS.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-07-15",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1403",
            "1317",
            "1323",
            "1337",
            "1412",
            "1245",
            "1341",
            "1440",
            "1308",
            "1386",
            "1338",
            "1422",
            "1441",
            "1408",
            "1324",
            "1361",
            "1434",
            "1362",
            "1363",
            "1318",
            "1407",
            "1437",
            "1409",
            "1307",
            "1414",
            "1320",
            "1305",
            "1507",
            "1319",
            "1420",
            "1375",
            "1306",
            "1410",
            "1364"
        ]
    },
    "CoronavirusPublicTransportation": {
        "id": "CoronavirusPublicTransportation",
        "title": "How to Safely Use Public Transportation during COVID-19 Pandemic",
        "description": "Public transportation is necessary for people to travel to and from work, and to help the local economy stay strong. However, because it puts people together in closed, tight spaces it creates special challenges in preventing the spread of coronavirus. Limit using public transportation as much as possible, especially if you have health conditions that put you at greater risk for contracting coronavirus.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusPublicTransportation.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-08-20",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1389",
            "1326",
            "1426",
            "1371",
            "1391",
            "1311",
            "1372",
            "1283",
            "1314",
            "1330",
            "1325",
            "1355",
            "1313",
            "1396",
            "1397",
            "1433",
            "1374",
            "1365",
            "1332",
            "1336",
            "1480",
            "1481",
            "1392",
            "1334",
            "1387",
            "1393",
            "1339",
            "1479",
            "1331",
            "1345",
            "1335",
            "1373",
            "1346",
            "1395",
            "1358",
            "1357"
        ]
    },
    "CoronavirusMaskUsageVar2": {
        "id": "CoronavirusMaskUsageVar2",
        "title": "Properly Using Facemasks - Ghana Version",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This is a variant animation for Ghana.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusMaskUsageVar2.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-08-20",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1295",
            "1506",
            "1469",
            "1284",
            "1344",
            "1298",
            "1470",
            "1404",
            "1322"
        ]
    },
    "PICSVar3": {
        "id": "PICSVar3",
        "title": "Properly Storing Dried Grains and Legumes Using Hermetically Sealed Bags (Bangladesh Version: COVID-19 Disclaimer)",
        "description": "Properly storing dry grains and legumes minimizes postharvest loss. You can store maize, rice, beans, lentils, and many other grains in what are called PICS bags. These bags can help prevent insect and mold damage during storage of dried grains and legumes. This animation is a variant for Bangladesh.",
        "category": "Agriculture",
        "shortTitle": null,
        "image": "PICSVar3.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-08-26",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1424",
            "1286",
            "1247"
        ]
    },
    "CoronavirusMarketplaceVar1": {
        "id": "CoronavirusMarketplaceVar1",
        "title": "How to Sell Safely in the Marketplace during the Coronavirus Pandemic - Variant for Bangladesh",
        "description": "The arrival of the Coronavirus changed many parts of community life. Your market may even have closed. As a market vendor, you need to stay healthy so the market can remain open so you can sell your goods and your community can access food and products. By following the actions in this video, you can help the market stay open and be an example to your customers on how to stay safe during this pandemic. This animation is a variant for Bangladesh.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusMarketplaceVar1.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-09-08",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1302",
            "1427",
            "1303"
        ]
    },
    "CoronavirusMaskUsageVar3": {
        "id": "CoronavirusMaskUsageVar3",
        "title": "Properly Using Facemasks - Bangladesh Version",
        "description": "In this video, you will learn about facemasks that healthcare experts recommend to reduce the spread of coronavirus. Following the guidelines shown in this video will help keep you, your family, and your community safe, and minimize the impact of COVID-19. This animation is a variant for Bangladesh.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusMaskUsageVar3.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-09-08",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1310",
            "1428",
            "1304",
            "1309"
        ]
    },
    "TripleSVar1": {
        "id": "TripleSVar1",
        "title": "Sweet Potato Roots for Timely Planting Material: The Triple S Method on How to Prepare and Store Roots (COVID Disclaimer)",
        "description": "This animation explains the Triple S Method for preparing and storing root vegetables.",
        "category": "Agriculture",
        "shortTitle": null,
        "image": "TripleS.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-09-15",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1316",
            "1508"
        ]
    },
    "CoronavirusNewcastleDiseaseVar1": {
        "id": "CoronavirusNewcastleDiseaseVar1",
        "title": "How to Protect Your Chickens from Newcastle Disease (COVID Disclaimer)",
        "description": "Raising chickens, guinea fowl, turkeys, and other birds for eggs and meat and for sale in the market, is an excellent way to provide fresh, healthy food for your family and money for your household. Disease however, can devastate a small flock. Newcastle disease is caused by a virus and is highly contagious among chickens, turkeys pheasants, partridges, and other wild and captive birds. Newcastle disease can cause all chickens in a flock to die in just a few days.",
        "category": "Health",
        "shortTitle": null,
        "image": "CoronavirusNewcastleDisease.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2021-10-29",
        "sdg": {
            "1": false,
            "2": false,
            "3": false,
            "4": false,
            "5": false,
            "6": false,
            "7": false,
            "8": false,
            "9": false,
            "10": false,
            "11": false,
            "12": false,
            "13": false,
            "14": false,
            "15": false,
            "16": false,
            "17": false
        },
        "videos": [
            "1476",
            "1466",
            "1463",
            "1416",
            "1461",
            "1462",
            "1467",
            "1465",
            "1464",
            "1471",
            "1486",
            "1472",
            "1473"
        ]
    },
    "PICSVar4": {
        "id": "PICSVar4",
        "title": "Properly Storing Dried Grains and Legumes Including Lentils Using Hermetically Sealed Bags",
        "description": "Properly storing dried grains and legumes including lentils minimizes post-harvest loss. You can store maize, rice, beans, lentils and many other grains in what are called PICS bags. When storing dried grains and legumes including lentils you can avoid insect pests and mold growth by sealing them in PICS bags.",
        "category": "Agriculture",
        "shortTitle": null,
        "image": "PICSVar4.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2023-06-28",
        "sdg": {
          "1": true,
          "2": true,
          "3": false,
          "4": true,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
          "9": false,
          "10": true,
          "11": false,
          "12": true,
          "13": false,
          "14": false,
          "15": true,
          "16": false,
          "17": false
        },
        "videos": [
            "1773",
            "1781",
            "1783",
            "1794",
            "1795",
            "1796",
            "1798",
            "1803"
        ]
    },
    "GroundnutPractices": {
        "id": "GroundnutPractices",
        "title": "Groundnut: Good Agricultural Practices for Production",
        "description": "Groundnut is a resilient and nutritious crop that can provide you good yield if you follow a few simple actions. This animation explains the best practices for growing groundnut. Follow these recommendations for a good start to the groundnut growing season.",
        "category": "Agriculture",
        "shortTitle": null,
        "image": "GroundnutPractices.jpg",
        "script": null,
        "metrics": null,
        "releaseDate": "2023-06-28",
        "sdg": {
          "1": true,
          "2": true,
          "3": false,
          "4": true,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
          "9": false,
          "10": true,
          "11": false,
          "12": true,
          "13": false,
          "14": false,
          "15": true,
          "16": false,
          "17": false
        },
        "videos": [
            "1054",
            "1055",
            "1056",
            "1057",
            "1058",
            "1505",
            "1644",
            "1661",
            "1675",
            "1678",
            "1690",
            "1705",
            "1710",
            "1713"
        ]
    },
}

const titleTranslations = {
    "NeemVar2":
    {
        "title": "Insecticide naturel à partir de graines de Neem",
        "desc": "Des composés insecticides naturels peuvent être extraits des graines de neem et pulvérisés sur les cultures pour prévenir les dégâts causés par les insectes nuisibles. Cette animation explique comment trier les fruits frais du Neem, les sécher, enlever les enveloppes extérieures, décortiquer les noyaux, trier les amandes, les broyer, mélanger la poudre dans l'eau, filtrer le mélange, puis pulvériser le filtrat  sur les cultures. Cette version de la video est destinée au Niger."
    },
    "NeemTeaBagVar1":
    {
        "title": "Les Sachets de poudre d'amande de Neem pour la gestion des ravageurs des cultures et l'augmentation des revenus des producteurs",
        "desc": "Les insectes ravageurs constituent une menace constante pour les agriculteurs. Ils sont responsables des pertes importantes de la production. Dans cette vidéo, vous apprendrez comment récolter, traiter, conditionner la poudre d'amandes de Neem et l'utiliser comme pesticide naturel. Les sachets en toile contenant la poudre de Neem (Neem Tea Ba) sont faciles à utiliser dans les champs. Ils peuvent aussi faire l'objet d'une activité génératrice de revenus. Cette version de la video a été conçue pour le Niger."
    },
    "Neem":
    {
        "title": "Insecticide naturel à partir de graines de Neem",
        "desc": "Des composés insecticides naturels peuvent être extraits des graines de neem et pulvérisés sur les cultures pour prévenir les dégâts causés par les insectes nuisibles. Cette animation explique comment trier les fruits frais du Neem, les sécher, enlever les enveloppes extérieures, décortiquer les noyaux, trier les amandes, les broyer, mélanger la poudre dans l'eau, filtrer le mélange, puis pulvériser le filtrat  sur les cultures. Cette version de la video est destinée au Niger."
    },
    "PHLJerryCanFTF":
    {
        "title": "Conservation de graines de niébé avec les bidons hermétiques",
        "desc": "Cette animation explique comment éviter les dégâts causés par les insectes lors du stockage du niébé après la récolte en utilisant les bidons. Le niébé peut être stocké dans un bidon hermétique pendant de longues périodes et en toute sécurité. Le bidon utilisé doit être propre et exempt de tout contaminant. Il ne doit jamais avoir été utilisé pour le stockage des matières dangereuses comme le carburant ou même les pesticides."
    },
    "PICS":
    {
        "title": "Conservation des graines de niébé avec le sac PICS",
        "desc": "Un stockage adéquat des graines de céréales et des légumineuses minimise les pertes après récolte. Vous pouvez stocker le maïs, le riz, les haricots, le niébé, les lentilles et de nombreuses autres cultures dans ce que l'on appelle les sacs PICS. Ces sacs hermétiques peuvent aider à prévenir les dommages causés par les insectes et les moisissures pendant le stockage."
    },
    "NeemTea":
    {
        "title": "Les Sachets de poudre d'amande de Neem pour la gestion des ravageurs des cultures et l'augmentation des revenus des producteurs",
        "desc": "Les insectes ravageurs constituent une menace constante pour les agriculteurs. Ils sont responsables des pertes importantes de la production. Dans cette vidéo, vous apprendrez comment récolter, traiter, conditionner la poudre d'amandes de Neem et l'utiliser comme pesticide naturel. Les sachets en toile contenant la poudre de Neem (Neem Tea Ba) sont faciles à utiliser dans les champs. Ils peuvent aussi faire l'objet d'une activité génératrice de revenus. Cette version de la video a été conçue pour le Niger."
    },
    "Solar":
    {
        "title": "Conservation des graines de niébé avec le traitement solaire",
        "desc": "Les bruches du niébé (Callosobruchus maculatus) sont responsables des pertes importantes dans le stocks de niébé (Vigna unguiculata) lors de conservation. Dans la vidéo animée, qui peut être utilisée sur des téléphones, nous décrivons une technique de solarisation pour éliminer les bruches du niébé avant le stockage des graines."
    },
    "IPMMaruca":
    {
        "title": "Lutte biologique contre le foreur de la gousse du niébé",
        "desc": "Le foreur de gousses de légumineuses, Maruca vitrata, cause des dommages importants à la culture du niébé en Afrique de l'Ouest. Cette légumineuse cultivée sur plus de 12,8 million d'hectares a une importance sociale et alimentaire. Le foreur de gousses est un insecte lépidoptère qui cause des dégâts importants à cette culture. Cette vidéo traite de l'utilisation des antagonistes biologiques pour réduire l'incidence de ce ravageur  dans les champs de niébé. L'objectif de la vidéo est de sensibiliser les producteurs  sur l'approche biologique  de gestion de ce nuisible."
    },
    "BiocontrolMarucaVar1":
    {
        "title": "Lutte biologique contre le foreur de la gousse du niébé",
        "desc": "Le foreur de gousses de légumineuses, Maruca vitrata, cause des dommages importants à la culture du niébé en Afrique de l'Ouest. Cette légumineuse cultivée sur plus de 12,8 million d'hectares a une importance sociale et alimentaire. Le foreur de gousses est un insecte lépidoptère qui cause des dégâts importants à cette culture. Cette vidéo traite de l'utilisation des antagonistes biologiques pour réduire l'incidence de ce ravageur  dans les champs de niébé. L'objectif de la vidéo est de sensibiliser les producteurs  sur l'approche biologique  de gestion de ce nuisible."
    },
    "GroundnutPractices":
    {
        "title": "Lutte biologique contre le foreur de la gousse du niébé",
        "desc": "Le foreur de gousses de légumineuses, Maruca vitrata, cause des dommages importants à la culture du niébé en Afrique de l'Ouest. Cette légumineuse cultivée sur plus de 12,8 million d'hectares a une importance sociale et alimentaire. Le foreur de gousses est un insecte lépidoptère qui cause des dégâts importants à cette culture. Cette vidéo traite de l'utilisation des antagonistes biologiques pour réduire l'incidence de ce ravageur  dans les champs de niébé. L'objectif de la vidéo est de sensibiliser les producteurs  sur l'approche biologique  de gestion de ce nuisible."
    }
};

/*const organization = {
    "name": "Ministry of Agriculture Livestock Fisheries and Cooperatives",
    "abbreviation": "MoALF&C",
    "logo": "https://sawbo-animations.org/images/external_logos/MoALF&C_logo.jpg",
    "website": "https://kilimo.go.ke/",
    "secondaryLogo": "https://sawbo-animations.org/images/external_logos/AIRC_Logo.png",
    "secondaryWebsite": "https://airc.go.ke/",
    "about": ""
}*/

// FTF org
const organization = {
    "id":"42",
    "name": "CIMMYT",
    "abbreviation":"",
    "logo": "https://sawbo-animations.org/AIDI-CIMMYT/img/CIMMYT.png",
    "website":"",
    "secondaryLogo": "",
    "secondaryWebsite": "",
    "about": ""
}

const siteInfo = {
    "route": "nigercowpeaipm",
    "title": "Accelerated Innovation Delivery Initiative (AID-|) Southern AfricaAméliorer la production de niébé au Niger",
    "translatedTitle": "",
    "navLink": organization.website,
    "partnerWebsite": organization.website,
    "partnerSecondaryWebsite": organization.secondaryWebsite,
    "partnerLogo": organization.logo,
    "partnerSecondaryLogo": organization.secondaryLogo,
    "aboutFiles": [
        // {
        //     "filename": "aboutFileFrench.html",
        //     "language": "French",
        //     "button": "Français"
        // },
        {
            "filename": "aboutFile.html",
            "language": "English",
            "button": "English"
        }
    ],
    "footerLogos": [
        {
            "file": "https://sawbo-animations.org/AIDI-CIMMYT/img/USAID.png",
            "link": "",
            "class": "footer-logo usaid-logo"
        }, 
        {
            "file": "https://sawbo-animations.org/AIDI-CIMMYT/img/CIMMYT_CGIAR_Logo_2024.jpg", 
            "link": "",
            "class": "footer-logo cimmyt-cgiar-logo"
        }, 
        { 
            "file": "https://sawbo-animations.org/AIDI-CIMMYT/img/IFDC.png",
            "link": "",
            "class": "footer-logo ifdc-logo"
        },
        { 
            "file": "https://sawbo-animations.org/AIDI-CIMMYT/img/Purdue.png",
            "link": "",
            "class": "footer-logo purdue-logo"
        },
        {
            "file": "https://sawbo-animations.org/AIDI-CIMMYT/img/SAWBO.png",
            "link": "https://sawbo-animations.org",
            "class": "footer-logo sawbo-logo"
        },
    ],
    "showSAWBOLogo": true,
    "showTranslations": false,
    "showDisclaimerText": true,
    "showSAWBOLink": false,
    "hideActiveLink": true
}

export default {
    videos,
    topics,
    organization,
    siteInfo,
    titleTranslations
}
